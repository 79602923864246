<template>
  <section class="tc-section mt-0 tc-bg text-on-screen">
    <div class="">
      <div class="row">
        <div class="col-xs-12 pl-0 pr-0">
          <slot></slot>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ContentService from "@/core/services/content.service";
export default {
  name: "TermsWrapper",
  props: ["headerClass"],
  computed: {
    currentLang: function () {
      return ContentService.getCurrentLang();
    },
    GetTermText() {
      switch (this.currentLang) {
        case "tr":
          return "Şart ve koşullar";

        case "ru":
          return "Правила и условия";

        case "en":
          return "Terms and conditions";

        default:
          return "წესები და პირობები";
      }
    },
  },
};
</script>
<style lang="scss">
.tc-section {
  // max-width: 1790px;
  max-width: 1920px;
  margin: 0 auto;
  padding: 24px 15px 18px 15px;

  .terms-head {
    display: inline-flex;
    color: white;
    font-size: 18px;
    padding: 0;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    width: 100%;
    margin-bottom: 32px;
    img {
      margin-right: 5px;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 24px 16px 14px 16px;
    font-size: 16px;
    .terms-head {
      margin-bottom: 22px;
    }
  }
}
</style>
