<template>
  <div class="wrapper">
    <div class="gallery-component" id="galleryComponent" :class="{'has-bg': galleryData.highlights.length > 0 || galleryData.videos.length > 0 || galleryData.articles.length > 0}">
      <div class="gallery-items">
        <div class="gallery-header text-font20 eb-font-bold mt-0" v-if="galleryData.highlights.length > 0">
          <p class="">ჰაილაითები </p>
          <a target="_blank" href="https://europop.ge/">გაიგე მეტი</a>
        </div>
        <div class="gallery-body">
          <div class="gallery-item">
            <div class="item" v-for="(item, i) in galleryData.highlights" :key="i">
              <a target="_blank" :href="item.videoUrl" class="video">

                <img class="video-image" :src="item.thumbnailUrl" alt="" />
                <img class="video-icon" :src="require('@/assets/gallery-component/video-icon.png')" />
              </a>
              <div class="result">
                <div class="stages_team eb-font-bold">
                  <div class="teamA text-uppercase">{{ generateTeamnames(item.game.firstTeam )}}</div>
                  <img :src="item.game.firstTeam.imageUrl" />
                  <div class="stages_scores text-font16 eb-font-light">
                    <span class="teamA_score"> {{ item.game.firstTeam.score }} </span>
                    <span class="score_line"></span>
                    <span class="teamB_score"> {{ item.game.secondTeam.score }} </span>
                  </div>
                  <img :src="item.game.secondTeam.imageUrl" />
                  <div class="teamB  text-uppercase">{{ generateTeamnames(item.game.secondTeam) }}</div>
                </div>
              </div>
              <div class="description text-font16 eb-font-medium">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="gallery-items" v-if="galleryData.videos.length > 0">
        <div class="gallery-header text-font20 eb-font-bold">
          <p>ვიდეოები </p>
          <a target="_blank" href="https://europop.ge/">გაიგე მეტი</a>
        </div>
        <div class="gallery-body">
          <div class="gallery-item">
            <div class="item" v-for="(item, i) in galleryData.videos" :key="i">
              <a :href="item.videoUrl" target="_blank" class="video">
                <img class="video-image" :src="item.imageUrl+`?uniq=${Uniq}`" alt="" />
                <img class="video-icon" :src="require('@/assets/gallery-component/video-icon.png')" />
              </a>

              <div class="description text-font16 eb-font-medium">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="gallery-items">
        <div class="gallery-header text-font20 eb-font-bold" v-if="galleryData.articles.length > 0">
          <p class="">სტატიები</p>
          <a target="_blank" href="https://europop.ge/">გაიგე მეტი</a>
        </div>
        <div class="gallery-body">
          <div class="gallery-item">
            <div class="item" v-for="(item, i) in galleryData.articles" :key="i">
              <a :href="item.videoUrl" target="_blank" class="video">
                <img class="video-image" :src="item.thumbnailUrl+`?uniq=${Uniq}`" alt="" />
              </a>

              <div class="description text-font16 eb-font-medium">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

import { mapGetters } from "vuex";
export default {
  name: "GalleryComponent",
  data() {
    return { 
      Uniq:null
    };
  },
  methods: {
    generateTeamnames(num) {
      return num.name.slice(0, 3);
    },
  
  },
  computed: {
    ...mapGetters({
      galleryData: "allGallery",
    }),
  },

  mounted() {
    this.Uniq=moment();
    console.log("GALLERY DATA: ",this.galleryData)
  },
};
</script>
<style lang="scss">
.gallery-component {
  width: 100%;
  margin-top: 199px;
  text-align: center;
  background-color: transparent;
  border-radius: 20px;
  padding: 30px 15px 18px;
  color: #000;
  &.has-bg {
    background-color: #fff;
  }
  .gallery-items {
    width: 100%;
    margin: 0 auto;
    .gallery-item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      // margin-top: 16px;
    }
    .item {
      border-radius: 12px;
      margin: 12px;
      width: calc(20% - 24px);
      border: 1px solid #eeeeee;
      @media only screen and (max-width: 1366px) {
        width: calc(25% - 24px);
      }
      @media only screen and (max-width: 1200px) {
        width: calc(33% - 24px);
      }
      @media only screen and (max-width: 768px) {
        width:100%;
        margin: 10px 0;
      }
   
      .description {
        text-align: left;
        padding: 24px 16px 30px 16px;
      }
      .result {
        background: rgba(210,210,210,0.3);
        background: -moz-linear-gradient(top, rgba(210,210,210,0.3) 0%, rgba(210,210,210,0.3) 50%, rgba(210,210,210,0.03) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(210,210,210,0.3)), color-stop(50%, rgba(210,210,210,0.3)), color-stop(100%, rgba(210,210,210,0.03)));
        background: -webkit-linear-gradient(top, rgba(210,210,210,0.3) 0%, rgba(210,210,210,0.3) 50%, rgba(210,210,210,0.03) 100%);
        background: -o-linear-gradient(top, rgba(210,210,210,0.3) 0%, rgba(210,210,210,0.3) 50%, rgba(210,210,210,0.03) 100%);
        background: -ms-linear-gradient(top, rgba(210,210,210,0.3) 0%, rgba(210,210,210,0.3) 50%, rgba(210,210,210,0.03) 100%);
        background: linear-gradient(to bottom, rgba(210,210,210,0.3) 0%, rgba(210,210,210,0.3) 50%, rgba(210,210,210,0.03) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d2d2d2', endColorstr='#d2d2d2', GradientType=0 );
        padding:12px 16px; 
        margin-top: -5px;
        .stages_team {
          display: flex;
          justify-content: space-evenly; 
          align-items: center;
          img {
            width: 25px; 
            margin: 0 10px;
            max-height: 32px;
          }
          .scores_time {
            height: 32px;
            border: 1px solid #f2f2f2;
            padding: 5px 10px;
            display: flex;
            align-items: center;
          }
          .stages_scores {
            background-color: #4a6547;
            color: #fff;
            height: 32px;
            display: flex;
            padding: 5px 10px;
            border: 1px solid #EAE8E8;
         
            .score_line {
              height: 20px;
              width: 1px;
              background-color: #fff;
              display: block;
              margin: 0 10px;
            }
          }
        }
      }
      .video {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &:hover {
          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            transition: background-color 0.2s;
            background-color: rgba(0, 0, 0, 0.5);
          }
        }

        .video-image {
          width: 100%;
          max-width: 100%;
          height: 172px;
          object-fit: cover;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }

        .video-icon {
          position: absolute;
          margin: auto;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  .gallery-header {
    display: flex;
    justify-content: space-between;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 40px;
    margin-bottom: 28px;
    &.mt-0 {
      margin-top: 0;
    }
    a {
      color: #F15B25; 
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position:relative;
      padding-right: 16px;
      &:hover {
        color: #f05a22;
        &::after {
          border-left: 2px solid #f05a22;
          border-top: 2px solid #f05a22;
        }
      }
      &::after {
        position:absolute;
        content: "";
        display: inline-block;
        border-left: 2px solid #F15B25;
        border-top: 2px solid #F15B25;
        width: 9px;
        height: 9px;
        transform: rotate(135deg); 
        right: 0;
        margin-top: 5px;
      }
    }
    @media only screen and (max-width: 600px) {
      font-size: 14px;
      margin-top: 24px;
      margin-bottom: 8px;
    }
  }
  @media only screen and (max-width: 600px) {
    padding: 14px 10px 0 10px;
    margin-top: 140px;
  }
}
</style>
