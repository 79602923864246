import axios from "axios";
const state = {
  teams: [],
  gallery: null
};

const getters = {
  allTeams(state) {
    return state.teams;
  },
  allGallery(state) {
    return state.gallery;
  },

};

const actions = {
  GetTeamsData() {
    axios
      .get(
        `${process.env.VUE_APP_DATA_API_BASE_URL}/youtubelegends/teams`
      )
      .then((response) => {
        console.log("GetTeamsData", response.data.teams)
        state.teams = response.data.teams.slice(0, 21);
      })
      .catch((error) => console.log("Error From Server ", error));
  },
  GetGalleryData() {
    axios
      .get(
        `${process.env.VUE_APP_DATA_API_BASE_URL}/youtubelegends/highlights-videos-articles`
      )
      .then((response) => {
        console.log("GetGalleryData", response.data)
        state.gallery = response.data;
      })
      .catch((error) => console.log("Error From Server ", error));
  },
};

const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations,
};
