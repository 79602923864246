<template>
  <div class="nav">
    <div class="wrapper">
      <div class="nav-inner" v-cloak>
        <div class="nav-icons" v-if="teamsList.length > 0">
          <swiper ref="TeamLogosCarousel" :options="TeamLogosCarouselOptions" class="team-logos-swiper">
            <swiper-slide v-for="(team, i) in teamsList" :key="i">
              <router-link :to="{ 
                name: 'players', 
                params: {
                  slug: FormatSlug(team.name),
                  },
                }">
                <img :src="team.imageUrl" alt="Logo">
              </router-link>
            </swiper-slide>
          </swiper>
        </div>
        <div class="nav-logo">
          <router-link :to="{ name: 'main'}">
            <img :src="require('@/assets/navbar-component/main-logo.png')" alt="Logo">
          </router-link>
        </div>
        <div class="nav-menu-toggle" @click="toggleMenu()" :class="{'clicked': showMobMenu}">
          <span class="first"></span>
          <span class="second"></span>
          <span class="third"></span>
        </div>
        <div class="nav-menu">

          <router-link class="link eb-font-bold" :to="{ name: 'main'}" exact>მთავარი</router-link>

          <router-link class="link eb-font-bold" :to="{ name: 'teams'}">გუნდები</router-link>

          <router-link class="link eb-font-bold" :to="{ name: 'gallery'}" v-show="showGalleryLink">გალერეა</router-link>

          <router-link class="link eb-font-bold" :to="{ name: 'main', hash: '#terms'}" exact>წესები</router-link>

          <a class="link eb-font-bold" href="https://fantasy.youtubelegends.ge/" target="_blank" v-show="showFantazyLink">Fantasy</a>

        </div>
      </div>
    </div>
    <div class="mob-menu" :class="{opened: showMobMenu}">
      <p class="link eb-font-medium" @click="$route.name == 'main' ? backToTopNav() : null">
        <router-link :to="{ name: 'main'}" exact><span>მთავარი</span></router-link>
      </p>
      <p class="link eb-font-medium" @click="$route.name == 'teams' ? backToTopNav() : null">
        <router-link :to="{ name: 'teams'}"><span>გუნდები</span></router-link>
      </p>
      <p class="link eb-font-medium" @click="$route.name == 'gallery' ? backToTopNav() : null" v-show="showGalleryLink">
        <router-link :to="{ name: 'gallery'}"><span>გალერეა</span></router-link>
      </p>
      <p class="link eb-font-medium">
        <router-link :to="{ name: 'main', hash: '#terms'}" exact><span>წესები</span></router-link>
      </p>
      <p class="link eb-font-medium">
        <a href="https://fantasy.youtubelegends.ge/" target="_blank" v-show="showFantazyLink"><span>Fantasy</span></a>
      </p>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
export default {
  name: "NavbarComponent",
  props: {
    showMobMenu: Boolean,
    toggleMenu: Function,
    backToTopNav: Function,
  },
    components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      showGalleryLink: false,
      showFantazyLink: true,
    };
  },
  computed: {
    ...mapGetters({
      teamsList: "allTeams",
    }),
     TeamLogosCarouselOptions() {
      return {
        slidesPerView: 7,
        slidesPerGroup: 7,
        spaceBetween: 16,
        initialSlide: 0,
        observer: true,
        observeParents: true,
        loop: true,
         speed: 3000,
         autoplay: {
          delay: 8000,
        },
        breakpoints: {
           1200: {
      spaceBetween: 20,
          },
        
        },
      };
    },
  },
  methods: {
    FormatSlug(name) {
      return name.toLowerCase().replace(" ", "-");
    },
  },

  mounted() {},
};
</script>

<style lang="scss">
@import "@/assets/scss/eb-mixin.scss";
[v-cloak] {
  display: none;
}
.nav {
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  .nav-inner {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 0px;
    width: 100%;
    background: url("../../assets/navbar-component/nav-bg.png") no-repeat center;
    background-size: 100% 100%;
    padding-left: 4%;
    padding-right: 4%;
    min-height: 119px;
    position: relative;

    &::after {
      content: "";
      display: none;
      position: absolute;
      height: 100%;
      width: 100vw;
      background: inherit;
      top: 0;
      left: -50vw;
      right: -50vw;
      margin-right: auto;
      margin-left: auto;
      z-index: -1;
    }

    .nav-icons {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      a {
        height: 38px;
        width: auto;
        display: block;
        img {
          max-width: 100%;
          height: 100%;
          // width: 100%;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
      @media only screen and (max-width: 1200px) {
        a {
          height: 32px;
          width: 32px;
        }
      }
      @media only screen and (max-width: 1200px) {
        display: none;
      }
    }
    .nav-logo {
      width: 141px;
      @include interpolate(width, 1200px, 1720px, 121px, 141px);

      align-self: center;
      img {
        max-width: 100%;
        height: auto;
        display: block;
      }
      @media only screen and (max-width: 1200px) {
        width: 121px;
        @include interpolate(width, 768px, 1200px, 90px, 121px);
      }
      @media only screen and (max-width: 768px) {
        width: 90px;
      }
    }
    .nav-menu {
      width: auto;
      display: flex;
      justify-content: flex-end;
      align-items: stretch;
      min-height: 100%;
      a.link {
        font-size: 16px;
        padding: 0px 28px;
        display: inline-flex;
        align-items: center;
        min-height: 100%;
        text-decoration: none;
        color: #7a7a7a;
        position: relative;
        @media only screen and (max-width: 1600px) {
          padding: 0px 16px;
        }
        &::after {
          content: "";
          position: absolute;
          opacity: 0;
          top: 0;
          left: calc(50% - 6px);
          border-bottom: 6px solid transparent;
          border-top: 6px solid #f05a22;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          transition: opacity 0.3s, transform 0.3s;
          transform: translateY(10px);
        }

        &:hover {
          color: #f05a22;
          &::after {
            opacity: 0;
            transform: translateY(10px);
          }
        }

        &.router-link-active {
          color: #f05a22;
          &::after {
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }

      @media only screen and (max-width: 1200px) {
        display: none;
      }
    }
    .nav-menu-toggle {
      justify-content: flex-end;
      align-self: center;
      color: #7a7a7a;
      display: none;
      cursor: pointer;
      background-color: transparent;
      border-radius: 50%;
      padding: 0;
      position: relative;
      z-index: 3;
      width: 44px;
      height: 44px;
      margin-top: 10px;
      transform: translateZ(0);
      backface-visibility: hidden;

      span {
        display: block;
        height: 2px;
        width: 18px;
        background-color: #7a7a7a;
        margin: 0;
        transition: transform 0.5s, top 0.5s, width 0.2s;
        position: absolute;
        left: calc(50% - 9px);
        top: calc(50% - 1px);

        &.first {
          margin-top: -5px;
        }
        &.second {
        }
        &.third {
          margin-top: 5px;
        }
      }
      &::after {
        content: "";
        position: absolute;
        background-color: #f1f1f1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 50%;
        z-index: -1;
      }
      &:hover span {
        background-color: #7a7a7a;
      }
      &.clicked {
        span {
          &.first {
            transform: rotate(-45deg);
            top: calc(50% + 5px);
            background-color: #7a7a7a;
          }
          &.second {
            transform: scaleX(0);
          }
          &.third {
            transform: rotate(45deg);
            top: calc(50% - 5px);
            background-color: #7a7a7a;
          }
        }
      }
      @media only screen and (max-width: 1200px) {
        display: inline-flex;
      }
    }

    @media only screen and (max-width: 1366px) {
      padding-left: 2%;
      padding-right: 2%;
    }
    @media only screen and (max-width: 1200px) {
      padding-left: 28px;
      padding-right: 28px;
      background: #fff;
      .nav-menu {
        justify-content: flex-end;
      }
      &::after {
        content: "";
        display: block;
      }
    }
    @media only screen and (max-width: 768px) {
      padding-left: 25px;
      padding-right: 25px;
    }
    @media only screen and (max-width: 500px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .mob-menu {
    position: absolute;
    width: 100%;
    top: 100%;
    background-color: #fff;
    padding-left: 0px;
    transform: scaleY(0);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s linear;
    transform-origin: top;
    z-index: 999;
    border-radius: 0 0 12px 12px;
    padding-top: 34px;
    padding-bottom: 34px;
    .link {
      // border-bottom: 1px solid #efefef;
      padding: 12px;
      padding-left: 0;
      a {
        font-size: 18px;
        line-height: 20px;
        color: #7a7a7a;
        position: relative;
        span {
          display: inline-block;
          margin-left: 35px;
        }
        &::after {
          content: "";
          position: absolute;
          opacity: 0;
          left: 0;
          top: calc(50% - 6px);
          border-bottom: 6px solid transparent;
          border-left: 6px solid #f05a22;
          border-top: 6px solid transparent;
          border-right: 6px solid transparent;
          transition: opacity 0.3s, transform 0.3s;
          transform: translateY(10px);
        }
        &:hover {
          color: #f05a22;
          &::after {
            opacity: 0;
            transform: translateY(10px);
          }
        }
        &.router-link-active {
          color: #f05a22;
          &::after {
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }
    }
    &.opened {
      opacity: 1;
      visibility: visible;
      transform: scaleY(1);
    }
  }
  .swiper-container {
    &.team-logos-swiper {
      width: 100%;
      max-width: 486px;
      margin-left: 0;
      margin-right: auto;
    }
  }
}
</style>