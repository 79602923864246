<template>
  <div class="results-survey stats-box">
    <h3 class="isTitle eb-font-black text-font24">ვინ მოიგებს თამაშს?</h3>
    <div class="hr"></div>

    <swiper ref="resultsSurveySwiper" :options="resultsSurveySwiper" class="results-survey-swiper-wrapper">

      <swiper-slide v-for="(gameDay,index) in ResultsSurveyData" :key="`gmday${index}`">
        <div class="results-item-wraper">
          <p class="isDate eb-font-medium text-font16">
            <span>{{ gameDay.date ? moment(gameDay.date).format("dddd DD MMMM") : "-"}}</span>
          </p>
          <div class="groups-item" v-for="group in gameDay.groups" :key="`grp${group.groupName}`">
            <p class="isGroup eb-font-roman text-font16">{{group.groupName}}</p>
            <div class="isContent">
              <div class="games-item" :class="[{inactive:CheckSelectedResults(game.id,game.firstTeam.teamId)||CheckSelectedResults(game.id,game.secondTeam.teamId)}]" v-for="(game,index) in group.games" :key="`gm${index}`">
                <div class="teams-item" @click="postResultsSelectedTeam(game.id,game.firstTeam.teamId)" :class="[{choozen:CheckSelectedResults(game.id,game.firstTeam.teamId)}]">
                  <input type="radio" :id="game.firstTeam.teamId+index" :value="game.firstTeam.name">

                  <label :for="game.firstTeam.teamId+index">
                    <div class="teams-item-text font-hl-black text-font16">
                      <div class="teams-item-logo"><img :src="game.firstTeam.imageUrl" alt=""></div>
                      <p>{{game.firstTeam.name}}</p>
                    </div>
                    <p class="circle"></p>
                  </label>
                </div>
                <div class="or-icon eb-font-black">VS</div>
                <div class="teams-item" @click="postResultsSelectedTeam(game.id,game.secondTeam.teamId)" :class="[{choozen: CheckSelectedResults(game.id,game.secondTeam.teamId)}]">
                  <input type="radio" :id="game.firstTeam.teamId+index" :value="game.secondTeam.name">

                  <label :for="game.secondTeam.teamId+index">
                    <div class="teams-item-text font-hl-black text-font16">
                      <div class="teams-item-logo"><img :src="game.secondTeam.imageUrl" alt=""></div>
                      <p>{{game.secondTeam.name}}</p>
                    </div>
                    <p class="circle"></p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="results-survey-swiper-btns results-survey-swiper-button-prev swiper-button-prev" slot="button-prev"></div>
    <div class="results-survey-swiper-btns results-survey-swiper-button-next swiper-button-next" slot="button-next"></div>
    <div class="results-survey-swiper-pagination swiper-pagination" slot="pagination"></div>

  </div>

</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ResultsSurveyComponent",
  props: { ResultsSurveyData: Array },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      LocalStorageData: null,
      AlreadyChosenResults: [],
      ResultsCustomerInfo: {
        choozenTeams: [],
        CanAnswer: true,
      },
    };
  },
  methods: {
    postResultsSelectedTeam(gameId, teamId) {
      console.log("SelectedTeamAndGroup: ", gameId, teamId);
      this.$emit("ChooseGameWinner",{  gameId:gameId,  teamId: teamId});
      this.SetSelectedResult(gameId, teamId);
      this.UpdateLocalStorageData();
    },
    UpdateLocalStorageData() {
      this.LocalStorageData = localStorage.getItem("YLRS");
    },
    CheckSelectedResults(gameId, teamId) {
    
      let ExistingIds = [];
      let localData = this.LocalStorageData;
      if (localData) {
        ExistingIds = JSON.parse(localData); 
        if (ExistingIds) {
          let CheckIfExisting = ExistingIds.some(
            (x) => x.game == gameId && x.team == teamId 
          );
         
          if (CheckIfExisting) {
            return true;
          }
        }
      }
      return false;
    },
    SetSelectedResult(gameId, teamId) {
      console.log("SetSelectedResult",gameId, teamId);
      let localData = localStorage.getItem("YLRS");
      if (localData) {
        this.AlreadyChosenResults = JSON.parse(localData); 
        if (this.AlreadyChosenResults) {
          let CheckIfExisting = this.AlreadyChosenResults.find(
            (x) => x.game == gameId && x.team == teamId
          ); 
          if (CheckIfExisting) { 
            return;
          }
        }
      }
      this.AlreadyChosenResults.push({ team: teamId, game: gameId });
      localStorage.setItem("YLRS", JSON.stringify(this.AlreadyChosenResults));
    },
  },
  computed: {
    resultsSurveySwiper() {
      return {
        slidesPerView: 1,
        spaceBetween: 10,
        initialSlide: 0,
        observer: true,
        observeParents: true,
        loop: false,
        autoplay: false,
        // autoHeight: true,
         pagination: {
          el: ".results-survey-swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".results-survey-swiper-button-next",
          prevEl: ".results-survey-swiper-button-prev",
        },
      };
    },
  },
  created() {
    this.UpdateLocalStorageData();
  },
  mounted() {},
};
</script>

<style lang="scss">
.results-survey {
  width: calc(50% - 13.5px);
  height: 600px;
  margin-left: 13.5px;
  background-color: #fff;
  padding: 24px 16px;
  border-radius: 10px;
  z-index: 9;
  position: relative;
  .isTitle {
    padding-bottom: 24px;
  }
  .hr {
    width: 100%;
    height: 1px;
    background: rgba(235, 236, 236, 0.4);
    margin-bottom: 12px;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: calc(100% + 48px);
      background: inherit;
      top: 0;
      left: -24px;
      margin-right: auto;
      margin-left: auto;
      z-index: 0;
    }
  }
  .results-survey-swiper-wrapper {
    max-height: 470px;
    overflow: hidden;
    @media only screen and (max-width: 1366px) {
      max-height: unset;
    }
  }
  .results-survey-swiper-pagination {
    position: absolute;
    width: 80%;
    margin-left: 10%;
    top: calc(100% - 31px);
    .swiper-pagination-bullet {
      display: inline-block;
      margin: 0px 2px;
      background-color: #d9d9d9;
      width: 8px;
      height: 8px;
      opacity: 1;
      border-radius: 50%;
      &.swiper-pagination-bullet-active {
        background-color: #de5a35;
      }
    }
  }
  .results-survey-swiper-btns {
    position: absolute;
    top: 100%;
    width: 32px;
    height: 32px;
    margin-top: -42px;
    z-index: 9;
    transition: all 0.2s;
    background: url("../../assets/home-component/results-survey-swiper-btn.png");

    &.swiper-button-prev {
      left: 24px;
      background: url("../../assets/home-component/results-survey-swiper-btn.png")
        center no-repeat;
      background-size: contain;
      transform: rotate(-180deg);
    }
    &.swiper-button-next {
      right: 24px;
      background: url("../../assets/home-component/results-survey-swiper-btn.png")
        center no-repeat;
      background-size: contain;
    }
    &:hover {
      cursor: pointer;
    }
    &.swiper-button-disabled {
      &.swiper-button-prev {
        background: url("../../assets/home-component/results-survey-swiper-btn-disabled.png")
          center no-repeat;
        background-size: contain;
        transform: rotate(0deg);
      }
      &.swiper-button-next {
        background: url("../../assets/home-component/results-survey-swiper-btn-disabled.png")
          center no-repeat;
        background-size: contain;
        transform: rotate(-180deg);
      }
    }
    @media only screen and (max-width:768px){
      &.swiper-button-prev {
        left:10px;
      }
      &.swiper-button-next {
      right: 10px;
      }
    }
  }
  .isDate {
    margin-bottom: 12px;
  }
  .groups-item {
    // margin-bottom: 20px;
  }
  .isGroup {
    margin-bottom: 12px;
  }

  .isContent {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;

    input[type="radio"] {
      display: none;
    }
    input[type="radio"] + label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer;
    }

    .games-item {
      background-color: #f1f1f1;
      width: calc(50% - 16px);
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: 20px;
      padding: 20px 20px 5px 20px;
      border-radius: 10px;
      position: relative;
    }
    .teams-item {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 0px);
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      margin: 0px;
      margin-bottom: 12px;
      min-height: 60px;
      padding-left: 12px;
      padding-right: 24px;
      text-align: left;
      position: relative;
      z-index: 1;

      .teams-item-text {
        flex: 1;
        padding-right: 10px;
        display: flex;
        align-items: center;
        .teams-item-logo {
          width: 40px;
          height: 40px;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin-right: 14px;
          img {
            width: auto;
            max-width: 26px;
            height: auto;
            max-height: 100%;
            object-fit: contain;
            display: block;
          }
        }
      }
      .circle {
        width: 30px;
        min-width: 30px;
        height: 30px;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &:after {
          content: "";
          width: 14px;
          min-width: 14px;
          height: 14px;
          background-color: #d9d9d9;
          border-radius: 50%;
          z-index: 1;
        }
        &:before {
          content: "";
          position: absolute;
          width: 8px;
          min-width: 8px;
          height: 6px;
          background: url("../../assets/home-component/check-icon.svg") center
            no-repeat;
          background-size: contain;
          z-index: 2;
          display: none;
        }
      }

      &:hover {
        cursor: pointer;
        border: 1px solid #f05a22;
        .circle {
          border: 1px solid #f05a22;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          &:after {
            background-color: #f05a22;
          }
        }
      }
      &.choozen {
        border: 1px solid #efeded;
        cursor: default;
        z-index: 2;
        .circle {
          border: 1px solid #f05a22;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          &:after {
            background-color: #f05a22;
          }
          &::before {
            display: block;
          }
        }
      }
    }
    .or-icon {
      background-color: #f1f1f1;
      font-size: 14px;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 9;
      right: 0;
      top: 50%;
      transform: translate(-25%, -50%);
    }
    .games-item.inactive {
      .teams-item {
        pointer-events: none;
      }
    }
  }

  @media only screen and (max-width: 1366px) {
    width: 100%;
    margin-left: 0px;
    height: auto;
  }
  @media only screen and (max-width: 1200px) {
    width: calc(100% - 0px);
    margin-left: 0px;
    height: auto;
  }

  @media only screen and (max-width: 768px) {
    height: auto;
    .groups-item {
      &:last-child {
        padding-bottom: 20px;
      }
    }
    .isContent {
      .games-item {
        width: calc(100% - 16px);
        margin-left: 0;
        margin-right:0;
        .teams-item {
          padding: 0 10px;
          margin: 0px;
          margin-bottom: 4px;
          height: 50px;
        }
        &:last-of-type {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>