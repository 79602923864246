<template>
  <div class="video-container">

    <!-- <p class="font-hl-bold text-font32 title text-uppercase">ყველაზე მასშტაბური ღონისძიება</p> -->
    <div class="playerHere">
      <vue-plyr class="vid-player">
        <div data-plyr-provider="youtube" :data-plyr-embed-id="id"></div>

      </vue-plyr>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoComponent",
  props:{
    id: String
  }
 
};
</script>

<style lang="scss">
.plyr__control--overlaid {
  background: #eb5315 !important;
}
.plyr--full-ui input[type="range"] {
  color: #eb5315 !important;
}
.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded="true"] {
  background: #eb5315 !important;
}
.video-container {
  // border: 1px solid #dddddd;
  color: #fff;
  // margin-top: 100px;
  margin-top: 219px;
  z-index: 2;
  position: inherit;
  p {
    text-align: center;
  }
  .playerHere {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    // margin-top: 47px;
    // max-height: 420px;
    // max-width: 750px;
    width: 100%;
    .vid-player {
      width: 100%;
      z-index: 1;
      border-radius: 10px;
      // max-height: 420px;
      overflow: hidden;
    }
  }
}
@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 768px) {
  .text-font32 {
    font-size: 16px;
  }
  .vid-player {
    border-radius: 15px !important;
  }
  .video-container {
    margin-top: 167px;
  }
  .playerHere {
    margin-top: 24px !important;
  }
}
</style>
