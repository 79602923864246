<template>
  <div class="stages stats-box">
    <div @click="StatsPopUpEvent" class="stages_title text-font16">ყველა თამაში</div>
    <div class="stages_table">

      <div class="stages_teams" v-for="(item, i) in FilteredData" :key="i">
        <div class="stages_date eb-font-roman">
          <span>{{ item.date ? moment(item.date).format("dddd DD MMMM") : "-" }}</span>

        </div>
        <div class="stages_group" v-for="(group, i) in item.groups" :key="i">
          <div class="line"></div>
          <!-- <div class="group_name eb-font-roman">{{ generateGroupNames(group.groupName) }}</div>
          <div class="line"></div> -->
          <div>
            <div class="stages_team" v-for="(game, i) in group.games" :key="i">
              <div class="teamA text-uppercase eb-font-bold">{{ game.firstTeam ? generateTeamnames(game.firstTeam) : "-" }}</div>
              <img :src="`${game.firstTeam.imageUrl}`" />
              <!-- <div v-if="moment(game.dateTime).isBefore(moment())" class="stages_scores text-font16 eb-font-light">
                <span class="teamA_score">
                  {{ game.firstTeam.score }}
                </span>
                <span class="score_line"></span>
                <span class="teamB_score">
                  {{ game.secondTeam.score }}
                </span>
              </div> -->
              <div class="scores_time eb-font-roman text-font16">{{ moment(game.dateTime).format("HH:mm") }}</div>
              <img :src="`${game.secondTeam.imageUrl}`" />

              <div class="teamB text-uppercase eb-font-bold">{{ game.secondTeam ? generateTeamnames(game.secondTeam) : "-" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    StatsData: Array, 
  },
  data() {
    return { 
      // finalPeriod: false,
     
    };
  },

  methods: {
    StatsPopUpEvent(){
       this.$emit("openStatsPopUp");
 
    },
   
    generateTeamnames(num) {
       if (num.name) {
      return num.name.slice(0, 3);
      }
    },
    // generateGroupNames(name) {
    //   switch (name) {
    //     case "A":
    //       return "Group A";
    //     case "B":
    //       return "Group B";
    //     case "SemiFinal":
    //       return "Semi Final";
    //     case "Final":
    //       return "Final";
    //     default:
    //       return "Group";
    //   }
    // },
    // getFinalPeriod(){
    // let gName =this.StatsData[0]?.groups[0]?.groupName;
    // this.finalPeriod = gName&& (gName=="Final" || gName=="SemiFinal"); 
    // }
     setLocaleData() {
          let localeDataKa = moment.updateLocale("ka", {
            months: [
              "იანვრი",
              "თებერვლი",
              "მარტი",
              "აპრილი",
              "მაისი",
              "ივნისი",
              "ივლისი",
              "აგვისტო",
              "სექტემბრი",
              "ოქტომბრი",
              "ნოემბრი",
              "დეკემბრი",
            ],
            weekdays: [
              "კვირა",
              "ორშაბათი",
              "სამშაბათი",
              "ოთხშაბათი",
              "ხუთშაბათი",
              "პარასკევი",
              "შაბათი",
            ],
          });

          let mKa = localeDataKa.months();
          let dKa = localeDataKa.weekdays();
          return mKa, dKa;
     },
  },
  computed:{
    FilteredData(){
      return this.StatsData.filter(x=>x.groups.every(q=>q.groupName=="B" || q.groupName=="A"));
    },

  },
  mounted(){
    this.setLocaleData()
    // this.getFinalPeriod()
    }
  
};
</script>

<style lang="scss">
.stages {
  width: 22%;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  min-height: 368px;
  max-height: 665px;
  // max-width: 368px;
  // min-width: 368px;
  // flex-basis: 368px;
  overflow: hidden;

  .stages_table {
    width: 100%;
    overflow: auto;
    padding-right: 30px;
    padding-left: 30px;

    @-moz-document url-prefix() {
      scrollbar-width: thin;
      scrollbar-color: #f15b25 transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #f15b25;

      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 5px !important;
    }
    &::before {
      content: "";
      display: block;
      width: 70%;
      height: 4.5px;
      background: #f05a22;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: 0;
      position: absolute;
      border-radius: 0px 0px 4px 4px;
    }
    .stages_group {
      padding-bottom: 16px;
      .line {
        width: 100%;
        background-color: #f1f2f3;
        height: 1px;
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
    .stages_teams {
      border-top: 1px solid #f1f2f3;
      padding-top: 10px;
      &:first-child {
        border: none;
        padding-top: 0;
      }
      .stages_team {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        align-items: center;
        img {
          width: 23px;
          height: auto;
          margin: 0 10px;
        }
        .teamA,
        .teamB {
          min-width: 35px;
        }
        .scores_time {
          height: 32px;
          border: 1px solid #8e8e8e;
          padding: 5px 10px;
          display: flex;
          align-items: center;
          border-radius: 16px;
        }
        .stages_scores {
          background-color: #4a6547;
          color: #fff;
          height: 32px;
          display: flex;
          padding: 5px 10px;
          border: 1px solid #eae8e8;
          .teamA_score,
          .teamB_score {
            line-height: 1.4;
          }
          .score_line {
            height: 20px;
            width: 1px;
            background-color: #fff;
            display: block;
            margin: 0 10px;
          }
        }
      }
      .stages_date {
        span {
          margin-right: 5px;
        }
      }
    }
  }
  .stages_title {
    color: #f05a22;
    position: relative;
    width: fit-content;
    height: fit-content;
    margin: 10px 0 20px 0;
    cursor: pointer;
    &::before {
      position: absolute;
      border-left: 2px solid #f05a22;
      border-top: 2px solid #f05a22;
      width: 9px;
      right: -15px;
      top: 7px;
      content: "";
      height: 9px;
      transform: rotate(135deg);
    }
  }
  // @media only screen and (max-width: 1366px) {
  //   width: 35%;
  // }

  @media only screen and (max-width: 1366px) {
    width: 100%;
    max-height: unset;
  }
}
</style>
