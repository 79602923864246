<template>
  <div class="players-component">
    <div class="wrapper">
      <!-- <h4>{{this.$route.params.slug}}</h4>
      <h4>{{slug}}</h4> -->
      <div class="players-page-inner" v-if="SelectedTeam">

        <div class="isHeader">
          <p class="isTitle eb-font-black text-font24 text-uppercase"><img :src="SelectedTeam.imageUrl" alt="">{{SelectedTeam.name}}</p>
          <p class="team-youtube-btn card-preview text-font18">
            <a target="_blank" :href="SelectedTeam.youtubeUrl">
              <img :src="require(`@/assets/youtube.svg`)" alt=""> <span v-if="!isXSWidth"> ნახე ჩვენი Youtube</span><span v-else>Youtube</span>
            </a>
          </p>
        </div>
        <div class="isContent">
          <div class="players-item" v-for="(player, i) in SelectedTeam.members" :key="i">
            <div class="player-img" v-if="player">
              <img :src="player.imageUrl" alt="">
            </div>
            <div class="player-info">
              <p class="eb-font-black text-font20 text-uppercase isName" v-if="player">{{player.fullName}}</p>
              <p class="eb-font-roman text-font14 isPos" v-if="player">{{player.position}}</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PlayersComponent",
  props: {
    "slug": String, 
  isXSWidth: Boolean},
  components: {},
  data() {
    return {};
  },
  methods: {
    FormatSlug(name) {
      return name.toLowerCase().replace(" ", "-");
    },
  },
  computed: {
    ...mapGetters({
      teamsList: "allTeams",
    }),
    SelectedTeam() {
      console.log("SELECTED:", this.teamsList);
      return this.teamsList.find((x) => this.FormatSlug(x.name) == this.slug);
    },
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
};
</script>

<style lang="scss">
.players-component {
  width: 100%;
  min-height: 100%;
  padding-top: 199px;
  padding-bottom: 100px;

  .players-page-inner {
    text-align: left;
    background: #ffffff;
    color: #000;
    box-shadow: 0px -60px 190px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    height: 100%;
    padding: 48px 13px;
    min-height: 50vh;
    .isHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .isTitle {
      // margin-bottom: 20px;
      padding-left: 14px;
      img {
        max-width: 100%;
        height: 40px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 18px;
      }
    }
    .team-youtube-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 254px;
      height: 64px;
      border: 1px solid #ff0000;
      border-radius: 8px;
      margin-right: 14px;
      color: #ff0000;
      font-size: 18px;
      line-height: 1.1;
      img {
        max-width: 100%;
        height: 19.5px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        // margin-left: 10px;
      }
      a {
        color: #ff0000;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .isContent {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: wrap;
      .players-item {
        width: calc(25% - 28px);
        margin: 20px 14px;
        min-height: 450px;
        // padding: 35px 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        background: #ffffff;
        cursor: default;
        border-radius: 8px;
        .player-info {
          align-self: flex-start;
          .isName {
            margin-bottom: 14px;
          }
        }
        .player-img {
          height: 370px;
          border: 1px solid #000000;
          margin-bottom: 25px;
          display: flex;
          // align-items: flex-end;
          align-items: stretch;
          justify-content: center;
          border-radius: 8px;
          overflow: hidden;
          img {
            // max-width: calc(100% - 50px);
            // max-height: 100%;
            max-width: 100%;
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-radius: 8px;
          }
        }
        @media only screen and (max-width: 1200px) {
          width: calc(50% - 28px);
          margin: 14px;
        }
        @media only screen and (max-width: 768px) {
          width: calc(100% - 20px);
          margin: 10px;
        }
        @media only screen and (max-width: 600px) {
          min-height: 320px;
          .player-img {
            min-height: 240px;
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
    }
    @media only screen and (max-width: 600px) {
      .isTitle {
        // margin-bottom: 20px;
        // padding-left: 14px;
        font-size: 16px;
        img {
          max-width: 100%;
          height: 26px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 8px;
        }
      }
      .team-youtube-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 122px;
        height: 29px;
        border: 1px solid #ff0000;
        border-radius: 8px;
        margin-right: 14px;
        color: #ff0000;
        font-size: 13px;
        line-height: 1.1;
        img {
          max-width: 100%;
          height: 14px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
          // margin-left: 5px;
        }
        a {
          color: #ff0000;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
  }
  @media only screen and (max-width: 600px) {
  }
}
</style>
