<template>
  <div class="col-xs-12 text-center pl-0 pr-0">
    <div class="video-swiper-outer">
      <swiper ref="thumbsSwiperVideo" :options="videosSwiperOptions" class="video-poster-thumbs-wrapper">
        <swiper-slide v-for="(item, index) in HomeVideoList" :key="index"  class="custom-swiper-slide">
          <a class="video-poster-thumb hasVideo"    target="_blank" :href="item.videoUrl"> 
            <img :src="item.imageUrl+`?uniq=${UniqueId}`" />
            <div class="isDate font-hl-bold text-font20">{{item.description}}</div> 
          </a>
        </swiper-slide>
        <swiper-slide class="empty-slide"> </swiper-slide>
      </swiper> 
      <div class="videos-stats-nav-btns"> 
      <div class="videosNStats-prev swiper-button-prev" slot="button-prev"></div>
    <div class="videosNStats-next swiper-button-next" slot="button-next"></div></div>
    </div>
 
  </div>

</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import moment from "moment";
export default {
  name: "VideosCarouselComponent",
  props: { HomeVideoList: Array },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      paginationEl: null,
      showVideoPopup: false,
      videoStreamId: "",
 
    };
  },
  methods: {
    getText(key) {
      return ContentService.getContentByKey(key);
    },
    openVideoPopup(videoId) {
      console.log("openVideo", videoId);
      this.showVideoPopup = true;
      this.videoStreamId = videoId;
      this.$parent.$emit("OpenVideo");
    },
    toggleVideoPopup() {
      this.$parent.$emit("OpenVideo");
      this.showVideoPopup = !this.showVideoPopup;
    },
  },
  beforeMount() {
    // this.tab1VideosList=this.tab1VideosList.reverse();
  },
  computed: {
    UniqueId(){
      return moment();
    },
    thumbsSwiperVideo() {
      return this.$refs.thumbsSwiperVideo.$swiper;
    },
    isMobile() {
      return CoreService.isMobile();
    },
    videosSwiperOptions() {
      return {
        slidesPerView: 2,
        spaceBetween: 10,
        initialSlide: 0,
        observer: true,
        observeParents: true,
        loop: false,
        autoplay: false,
        scrollbar: {
          el: ".swiper-scrollbar",
          clickable: true,
        },
        navigation: {
          nextEl: ".videosNStats-next",
          prevEl: ".videosNStats-prev",
        },
        breakpoints: {
          500: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1366: {
            slidesPerView: 4,
          },
        },
      };
    },
  },
};
</script>
<style lang="scss">
.videos-stats-nav-btns{ 
    position: absolute;
    top: -60px;
    right: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    div{
      width: 44px;
    height: 44px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
 
    &:before{
      content:"";
      width:100%;
      height: 100%;
      background-image: url("../../assets/arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px; 
      transform: rotate(180deg); 
      transition: opacity 0.2s;
    }
    @media only screen and (max-width:768px){
      width:32px;
      height: 32px;
      
        &:before{
        background-size: 8px; 
      }
    }
    &:first-child{
      margin-right: 12px;
      &:before{
        transform: rotate(0deg);
      }
    }
    &:hover{
      opacity: 0.8;
    }
    &.swiper-button-disabled{
      opacity: 0.3;
    cursor: default;
    }
    }
}
.swiper-scrollbar {
  margin-top: 14px;
  background-color: #303436;
  .swiper-scrollbar-drag {
    background-color: #f05a22;
    height: 4px;
  }
}
section {
  position: relative;
  z-index: auto;
}
.video-swiper-outer {
  width: 100%;
  position: relative;
  margin-top: 24px;
  .swiper-pagination {
    &.swiper-pagination-video {
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translate(-50%, -50%);
      margin-top: 20px;
      margin-left: -20px;
      z-index: 9;
      .swiper-pagination-bullet {
        background-color: rgba(255, 255, 255, 0.3);
        width: 16px;
        height: 4px;
        border-radius: 20px;
        margin-left: 4px;
        margin-right: 4px;
        position: relative;
   
        &:hover {
          cursor: pointer;
        }
        &::after {
          content: "";
          position: absolute;
          width: 16px;
          height: 16px;
          top: -8px;
          left: 0;
          background-color: transparent;
          z-index: -1;
        }
      }

      .swiper-pagination-bullet-active {
        background-color: #ef602a;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
    .swiper-wrapper {
      margin-bottom: 0;
    }
    .swiper-pagination.swiper-pagination-video {
      display: none;
    }
  }
}
 
.swiper-slide.custom-swiper-slide {
  display: inline-block;
  width: auto; 
  min-height: 100%;
  height: auto;
  &.empty-slide {
    width: 50px;
  }
  @media only screen and (max-width: 500px) {
    min-width: 250px;
  }
}
.video-poster-thumb { 
  cursor: pointer;
  position: relative;
  width: auto;  
  border-radius: 14px;
  .isDate {
    padding:0;
    margin-top: 6px;
    font-size: 20px; 
    position: absolute;
    max-width: 296px;
    left: 28px;
    bottom: 28px;
    color: #fff;
    z-index: 999;
    @media only screen and (max-width:1280px){
      font-size: 16px; 
    }
    @media only screen and (max-width:768px){
      font-size: 14px;
      left:14px;
      bottom:18px; 
    }
  }
  img {
    border-radius: 14px;
    width: 100%;
    width: 100%; 
    display: block;
    background-color: #e1e1e1;
  }
  &.hasVideo {
    iframe {
      border: none;
      outline: none;
    }
    &:before {
      content: "";
      background: url("../../assets/home-component/video-btn.png") center
        no-repeat;
      background-size: contain;
      position: absolute;
      width: 47px;
      height: 40px;
      background-repeat: no-repeat;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
      cursor: pointer;
      // pointer-events: none;
    }
    &:after {
      content: "";
     pointer-events: none;
     background: rgba(0,0,0,0.6);
background: -moz-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.24) 27%, rgba(0,0,0,0.24) 31%, rgba(0,0,0,0.24) 33%, rgba(0,0,0,0.9) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.6)), color-stop(27%, rgba(0,0,0,0.24)), color-stop(31%, rgba(0,0,0,0.24)), color-stop(33%, rgba(0,0,0,0.24)), color-stop(100%, rgba(0,0,0,0.9)));
background: -webkit-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.24) 27%, rgba(0,0,0,0.24) 31%, rgba(0,0,0,0.24) 33%, rgba(0,0,0,0.9) 100%);
background: -o-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.24) 27%, rgba(0,0,0,0.24) 31%, rgba(0,0,0,0.24) 33%, rgba(0,0,0,0.9) 100%);
background: -ms-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.24) 27%, rgba(0,0,0,0.24) 31%, rgba(0,0,0,0.24) 33%, rgba(0,0,0,0.9) 100%);
background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.24) 27%, rgba(0,0,0,0.24) 31%, rgba(0,0,0,0.24) 33%, rgba(0,0,0,0.9) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      border-radius: 14px;
    }
  }
}
.swiper-container.video-poster-thumbs-wrapper {
  width: 120%;
}

.video-popup-wrapper {
  width: 100%;
  // height: calc(100% - 58px);
  height: calc(100% - 0px);
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 99999999;
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 1720px;
  margin: 0 auto;
  border-radius: 16px;
  .video-popup-content {
    background-color: #25292b;
    padding: 0;
    border-radius: 16px;
    position: relative;
    width: 95%;
    max-width: 690px;
    //height: 100%;
    // max-height: 350px;
    //padding-bottom: 41%;
    overflow: hidden;
    padding-top: 41.25%;
    top: 7%;
    position: relative;
    &:before {
      content: "";
      height: 25%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 999;
    }
    &:after {
      content: "";
      height: 38px;
      width: 50px;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 999;
    }
  }

  .video-popup-close {
    width: 32px;
    height: 32px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 7px;
    right: 7px;
    cursor: pointer;
    z-index: 999999999;
    &:before {
      content: "";
      position: absolute;
      height: 2px;
      width: 12px;
      transform: rotate(45deg);
      background-color: #fff;
      border-radius: 2px;
    }
    &:after {
      content: "";
      position: absolute;
      height: 2px;
      width: 12px;
      transform: rotate(-45deg);
      background-color: #fff;
      border-radius: 2px;
    }
    &:hover {
      transform: scale(0.95);
    }
  }
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 8px;
    outline: none;
    border: none;
  }
  @media only screen and (max-width: 768px) {
    height: 100%;
    .video-popup-content {
      height: 100%;
      width: 100%;
      top: 0;
      padding-top: 0;
      max-width: 100%;
    }
  }
}
</style>
