<template>
  <div class="teams-component eb-font-roman">
    <div class="wrapper">
      <div class="teams-page-inner">

        <p class=" isTitle eb-font-black text-font24">გუნდები</p>
        <div class="isContent">
          <div class="teams-item" v-for="team in teamsList" :key="team.id">
            <router-link class="teams-item-link" :to="{
                  name: 'players',
                  params: {
                    slug: FormatSlug(team.name),
                  },
                }">
              <div class="team-name">
                <p class="eb-font-black text-font20 text-uppercase">{{team.name}}</p>
              </div>
              <div class="team-logo">
                <img :src="team.imageUrl" alt="">
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../router";
import { mapGetters } from "vuex";
export default {
  name: "TeamsComponent",
  data() {
    return {};
  },

  props: {},

  methods: {
    FormatSlug(name) {
      return name.toLowerCase().replace(" ", "-");
    },
  },
  computed: {
    ...mapGetters({
      teamsList: "allTeams",
    }),
  },
  created() {},
  mounted() {
    console.log("TEAMLIST: ", this.teamsList);
  },
};
</script>

<style lang="scss">
.teams-component {
  width: 100%;
  min-height: 100%;
  padding-top: 199px;
  padding-bottom: 100px;

  .teams-page-inner {
    text-align: left;
    background: #ffffff;
    color: #000;
    box-shadow: 0px -60px 190px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    height: 100%;
    padding: 48px 13px 64px 13px;
    min-height: 50vh;
    .isTitle {
      margin-bottom: 30px;
      padding-left: 14px;
    }
    .isContent {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: wrap;
      .teams-item {
        width: calc(50% - 28px);
        margin: 16px 14px;
        min-height: 240px;
        padding: 35px 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // background: #ffffff;
        // border: 1px solid #000000;
        background: url("../../assets/teams-component/team-item-bg.png")
          no-repeat center;
        background-size: 100% 100%;

        border-radius: 1px;
        cursor: pointer;
        .team-name {
          align-self: center;
        }
        .team-logo {
          width: 126px;
          height: 171px;
          text-align: center;
          img {
            max-width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
            margin: 0 auto;
          }
        }
        @media only screen and (max-width: 768px) {
          width: calc(100% - 20px);
          margin: 10px;
        }
        @media only screen and (max-width: 600px) {
          min-height: 137px;
          padding: 15px 15px;
          .team-logo {
            width: 68px;
            height: 93px;
            text-align: center;
          }
        }
      }
      .teams-item-link {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        color: #000;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 140px;
    padding-bottom: 20px;
    .teams-page-inner {
      padding: 20px 10px;
      .isTitle {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
