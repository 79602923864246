<template>
  <div class="winner-survey stats-box">
    <h3 class="isTitle eb-font-black text-font24">ვინ მოიგებს ჩემპიონატს?</h3>
    <div class="hr"></div>
    <div class="isContent">
      <div class="quis-item" ref="answers" v-for="team in WinnerSurveyData" :key="team.id" @click="postWinnerAnswerId(team.id)" :class="[{clicked: team.id == WinnerCustomerInfo.AnswerId},{selected:!WinnerCustomerInfo.CanAnswer}]">

        <div v-if="!WinnerCustomerInfo.CanAnswer" class="quis-item-fill" :class="{ full: team.percentage == 100 }" :style="`width:${team.percentage}%`"></div>
        <div class="quis-item-text font-hl-black text-font16">
          <div class="team-logo">
            <img :src="team.imageUrl" alt="">
          </div>
          <p>{{team.name}}</p>
        </div>
        <p class="percent font-hl-black text-font14" :class="{ full: team.percentage == 100 }" v-if="!WinnerCustomerInfo.CanAnswer">{{formatPercent(team.percentage)}}%</p>
        <p class="circle"></p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "WinnerSurveyComponent",
  props: {WinnerSurveyData:Array},
  data() {
    return {
      WinnerCustomerInfo: {
        AnswerId: null,
        CanAnswer: true,
      }, 
    };
  },
  methods: {
    postWinnerAnswerId(id) {
      if(!this.WinnerCustomerInfo.CanAnswer){
        return;
      }
      console.log("postWinnerAnswerId", id); 
      this.WinnerCustomerInfo.AnswerId = id;
      this.WinnerCustomerInfo.CanAnswer = false;
      localStorage.setItem("YLWS", id); 
      this.$emit("ChooseWinnerTeam",id)
    },
 
    formatPercent(v) {
      return Number(parseFloat(v).toFixed(2));
    },
  },
  computed: {
 
  },
  beforeMount(){
    const AlreadyChosenWinner = localStorage.getItem("YLWS"); 
    console.log("DATA EXISTS ON LOCAL:",AlreadyChosenWinner)
          if (AlreadyChosenWinner) { 
            this.WinnerCustomerInfo.AnswerId=AlreadyChosenWinner;
            this.WinnerCustomerInfo.CanAnswer=false;
          } 
  }
};
</script>

<style lang="scss">
.winner-survey {
  width: calc(50% - 17.5px);
  height: 600px;
  margin-right: 17.5px;
  background-color: #fff;
  padding: 24px 14px;
  border-radius: 10px;
  .isTitle {
    padding-bottom: 24px;
  }
  .hr {
    width: 100%;
    height: 1px;
    background: rgba(235, 236, 236, 0.4);
    margin-bottom: 25px;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: calc(100% + 48px);
      background: inherit;
      top: 0;
      left: -24px;
      margin-right: auto;
      margin-left: auto;
      z-index: 0;
    }
  }
  .isContent {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    max-height: 470px;
    overflow: hidden;
    overflow-y: auto;

    @-moz-document url-prefix() {
      scrollbar-width: thin;
      scrollbar-color: #f15b25 transparent;
    }
    scroll-behavior: smooth;
    &::-webkit-scrollbar-thumb {
      background-color: #f15b25;

      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 5px !important;
    }
    .quis-item {
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      width: calc(50% - 20px);
      background: #f8f8f8;
      border: 1px solid #efeded;
      border-radius: 10px;
      margin: 0px 10px 5px;
      min-height: 54px;
      padding: 0 12px;
      text-align: left;
      position: relative;
      z-index: 1;
      .quis-item-fill {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 8px 0px 0px 8px;
        height: 100%;
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        min-width: 0px;
        background: linear-gradient(
          270deg,
          #d9d9d9 0%,
          rgba(248, 248, 248, 0) 102.63%
        );
        z-index: -1;
        &.full {
          border-radius: 8px 8px 8px 8px;
        }
      }
      .quis-item-text {
        flex: 1;
        padding-right: 10px;
        display: flex;
        align-items: center;
        .team-logo {
          width: 40px;
          height: 40px;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin-right: 14px;
          img {
            width: 100%;
            max-width: 26px;
            height: 100%;
            max-height: 30px;
            object-fit: contain;
          }
        }
      }
      .circle {
        width: 30px;
        min-width: 30px;
        height: 30px;

        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        // position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &:after {
          content: "";
          width: 14px;
          min-width: 14px;
          height: 14px;
          background-color: #d9d9d9;
          border-radius: 50%;
          z-index: 1;
        }
        &:before {
          content: "";
          position: absolute;
          width: 8px;
          min-width: 8px;
          height: 6px;
          background: url("../../assets/home-component/check-icon.svg") center
            no-repeat;
          background-size: contain;
          z-index: 2;
          display: none;
        }
      }
      .percent {
        flex-basis: 70px;
        color: #000;
        display: flex;
        justify-content: center;
      }
      &:hover {
        border: 1px solid #f05a22;
        .circle {
          border: 1px solid #f05a22;
          border-radius: 50%;
          // position: relative;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          &:after {
            background-color: #f05a22;
          }
        }
      }
      &.clicked {
        border: 1px solid #efeded;
        cursor: default;
        z-index: 2;
        .circle {
          border: 1px solid #f05a22;
          border-radius: 50%;
          // position: relative;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          &:after {
            background-color: #f05a22;
          }
          &::before {
            display: block;
          }
        }
        .quis-item-fill {
          background: linear-gradient(
            270deg,
            #f05b24 0%,
            rgba(240, 91, 36, 0) 102.63%
          );
        }
      }
      &.selected {
        pointer-events: none;
      }
      // .quis-item-notification {
      //   position: absolute;
      //   top: calc(0% - 45px);
      //   right: 0;
      //   background-color: #f05a22;
      //   padding: 9.5px 20px;
      //   width: auto;
      //   border-radius: 8px;
      //   transform: scaleY(0);
      //   transition: all 0.4s;
      //   transform-origin: bottom;
      //   color: #fff;
      //   z-index: 9;
      //   &.show {
      //     transform: scaleY(1);
      //   }
      //   &:after {
      //     content: "";
      //     display: inline-block;
      //     position: absolute;
      //     top: 99%;
      //     left: 20%;
      //     width: 16px;
      //     height: 16px;
      //     border: 8px solid transparent;
      //     border-top: 8px solid #f05a22;
      //     border-radius: 3px;
      //   }
      // }
      @media only screen and (max-width: 1200px) {
        width: calc(100% - 0px);
        margin: 0px 0px 5px;
      }
    }
  }
  @media only screen and (max-width: 1366px) {
    // width: calc(65% - 37px);
    width: calc(65% - 10px);
    margin-right: 10px;
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
    height: auto;
    margin-left: 0px;
    margin-right: 0px;
    padding: 24px 24px;
    .isContent {
      max-height: unset;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 30px 10px;
    height: auto;
    .isTitle {
      font-size: 14px;
    }
    .isContent {
      .quis-item {
        padding: 0 10px;
        margin: 0px;
        margin-bottom: 4px;
        height: 50px;
        .quis-item-text {
          font-size: 14px;
        }
      }
    }
  }
}
</style>