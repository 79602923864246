<template>
  <section class="custom-card-wrapper">
    <div class="custom-card-box" :class="{processing:Processing}">
      <div class="close-btn" @click="ToggleCustomCard"></div>
      <p class="title text-font20 eb-font-bold">შექმენი შენი ბარათი</p>
      <div class="custom-card-content">
        <div class="preview-outer">
          <div id="CardPreview" class="card-preview" :class="[`color-${ActiveColor}`,{borderless:ScreenShot}]">
            <img :src="require(`../../assets/card/icon${ActiveIcon}.svg`)" />
            <input class="eb-font-bold" :class="{fill:CardName}" placeholder="ჩაწერე ბარათის სახელი*" v-model="CardName" />
            <div class="temp-area" :class="{fill:CardName}" v-if="ScreenShot">{{ CardDesc }}</div>
            <textarea rows="3" maxlength="90" v-else :class="{fill:CardDesc}" placeholder="ჩაწერე ბარათის მოკლე აღწერა*" v-model="CardDesc">

              </textarea>
          </div>
        </div>
        <div class="card-settings">
          <div class="color-settings">
            <span class="title eb-font-medium text-font14">აირჩიე ბარათის ფერი:</span>
            <div class="colors-wrapper">
              <span @click="ChangeColor(1)" :class="{active:ActiveColor==1}"> </span>
              <span @click="ChangeColor(2)" :class="{active:ActiveColor==2}"> </span>
              <span @click="ChangeColor(3)" :class="{active:ActiveColor==3}"> </span>
            </div>
          </div>
          <div class="icon-settings">
            <span class="title  eb-font-medium text-font14">აირჩიე ბარათის აიქონი:</span>
            <div class="icons-wrapper">
              <span @click="ChangeIcon(1)" :class="{active:ActiveIcon==1}"></span>
              <span @click="ChangeIcon(2)" :class="{active:ActiveIcon==2}"></span>
              <span @click="ChangeIcon(3)" :class="{active:ActiveIcon==3}"></span>
            </div>
          </div>
          <textarea maxlength="150" v-model="ExtraInfo" class="card-additional-info" placeholder="რატომ გგონია რომ ეს ქარდი იმუშავებს?"> </textarea>
        </div>
      </div>
      <textarea maxlength="150" v-model="ExtraInfo" class="card-additional-info mob" placeholder="რატომ გგონია რომ ეს ქარდი იმუშავებს?"> </textarea>

      <button @click="SaveAsPNG" class="card-share-btn text-font14 eb-font-medium" :class="{ready:EnableShare&&!ScreenShot}">გააზიარე შენი ბარათი</button>
    </div>
  </section>
</template>

<script>
 
 import axios from "axios";
 import html2canvas from "html2canvas";
export default {
  name: "CustomCard",
 data(){
  return {
    ResponseText:"",
    ActiveColor:1,
    ActiveIcon:1,
    CardName:"", 
    CardNameError:false,
    CardDesc:"", 
    CardDescError:false,
    ScreenShot:false,
    ExtraInfo:"",
    Processing:false,
    CustomCardId:""
  }
 },
 props:{
  
 },
 computed:{
  EnableShare(){
    return this.CardName&&this.CardDesc;
  }
 },
  methods: {
    ToggleCustomCard(){
     
      this.$emit("ToggleCustomCard")
    },
   ChangeColor(newColor){
    this.ActiveColor=newColor;
   },
   ChangeIcon(newIcon){
    this.ActiveIcon=newIcon;
   },
   CreateCustomCard(data) {
      let htmlData = `
          <!DOCTYPE html>
          <html>
            <head>
                <title>Youtubelegends</title> 
                <meta property="og:url"          content="https://youtubelegends.ge/" />
                <meta property="og:type"          content="website"/>
                <meta property="og:title"         content="Youtube League ჩემი წესებით!" />
                <meta property="og:description"    content="მზად ხარ მოედანზე კარგი დროის გასატარებლად? ცეცხლი აინთო თამაში დაიწყო!" />
                <meta property="og:image"        content="@ImageUrl" />
                <meta property="og:image:width"        content="160" />
                <meta property="og:image:height"        content="160" />
            </head>
            <body>  
              <script> 
                window.addEventListener("DOMContentLoaded", (event) => {
                                  window.location.replace("https://youtubelegends.ge/");
                });
               <\/script>
            </body>
          </html>
          `;
      
       axios
        .post(
          `${process.env.VUE_APP_DATA_API_BASE_URL}/youtubelegends/custom-card`,
          {
            imageBase64: data.imageData,
            name: data.CardName,
            description: data.CardDesc,
            whyWouldThisCardWork: data.ExtraInfo,
            htmlStr: htmlData,
          }
        )
        .then((response) => {
          this.ResponseText+=" /CreateCustomCard THEN / "+response;
          console.log("CUSTOMCARD RESPONSE: ",response.data.cardId)
          this.CustomCardId = response.data.cardId;
          setTimeout(() => { 
            //  this.$refs.CustomShareBtn.click();
             window.open(`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpromotions-api.europebet.com%2Fyoutubelegends%2Fcustom-card%2F${this.CustomCardId}&amp;src=sdkpreparse`, "_top");
          }, 100);
          // setTimeout(() => { 
          //    this.ToggleCustomCard();
          // }, 400);
          
        })
        .catch((error) => {
          this.ResponseText+=" /CreateCustomCard Error / "+error;
          this.Processing=false;
          console.log("Error From Server ", error)
        });
    },
   SaveAsPNG() {
   
    if(!this.EnableShare){
      return;
    }
 
    this.Processing=true;
    this.ScreenShot=true;
    let that=this;
    setTimeout(() => {
      html2canvas(document.getElementById("CardPreview"), {
        logging: true,
        profile: true,
        useCORS: true,
        letterRendering: true
      }).then(function (canvas) {
        that.ResponseText+="/html2canvas THEN/"
        var data = canvas.toDataURL("image/png", 1);
        var src = encodeURI(data).replace("data:image/png;base64,","");
        console.log("IMG SRC: ",src);
        that.CreateCustomCard({CardName:that.CardName,CardDesc:that.CardDesc,ExtraInfo:that.ExtraInfo,imageData:src}); 
      
      }).catch((error) => {
          that.ResponseText+="/html2canvas Error/ "+error;
          that.Processing=false;
          console.log("Error From html2canvas ", error)
        });;
    }, 1000);
 
    },
  },
  mounted(){
    
   
 
  }
};
</script>

<style lang="scss">
// .fb-share-button{
//   pointer-events: none;
// }
.custom-card-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(45, 45, 45, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    align-items: flex-start;
  }
  overflow: hidden;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;

  overflow-y: auto;

  @-moz-document url-prefix() {
    scrollbar-width: none;
  }

  &::-webkit-scrollbar {
    width: 0px !important;
  }

  .custom-card-box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    flex-direction: column;
    padding-bottom: 30px;
    position: relative;
    &.processing {
      cursor: wait;
      * {
        pointer-events: none;
      }
      .close-btn {
        pointer-events: all;
        cursor: pointer;
      }
    }
    .close-btn {
      position: absolute;
      width: 26px;
      height: 26px;
      background-color: #d9d9d9;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      top: 12px;
      right: 12px;
      &:before {
        content: "";
        width: 14px;
        height: 3px;
        background-color: #fff;
        transform: rotate(45deg);
        position: absolute;
      }
      &:after {
        content: "";
        width: 14px;
        height: 3px;
        background-color: #fff;
        transform: rotate(-45deg);
        position: absolute;
      }
    }
    .title {
      display: block;
      color: #3c3c3c;
      text-align: left;
      padding: 0;
      margin-bottom: 10px;
    }
    p {
      &.title {
        text-align: center;
        padding: 24px 16px 20px 16px;
        margin-bottom: 0;
        color: #3c3c3c;
      }
    }

    .custom-card-content {
      padding: 16px 80px 0 80px;
      border-top: 1px solid rgba(235, 236, 236, 0.6);
      display: flex;
      .preview-outer {
        display: inline-flex;
        overflow: hidden;
        border-radius: 12px;
        border: 1px solid #000;
      }
      .card-preview {
        padding: 16px 34px 16px 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: background-color 0.4s;

        img {
          width: 90px;
          margin-bottom: 20px;
        }

        .temp-area,
        input,
        textarea {
          resize: none;
          border-radius: 8px;
          background-color: rgba(249, 247, 211, 0.7);
          border: 1px dashed rgba(255, 255, 255, 1);
          // border: 1px solid #000;
          padding: 10px 16px;
          font-size: 12px;
          width: 220px;
          outline: none;
          color: black;
          text-align: center;
          // &:focus,
          &.fill {
            text-align: center;
            background-color: #f9ec2f;
            // border: 1px solid rgba(0, 0, 0, 0);
            border: 1px dashed rgba(255, 255, 255, 0);
            color: black;
            font-weight: 700;
          }
        }
        input {
          height: 40px;
          padding: 0 16px;
          font-size: 15px;
          line-height: 40px;
          color: black;
          &::placeholder {
            font-size: 12px;
            color: black;
          }
        }
        .temp-area,
        textarea {
          white-space: normal;
          height: 70px;
          overflow: hidden;
          margin-top: 12px;
          color: black;
          &::placeholder {
            color: black;
          }
        }
        &.color-1 {
          background-color: #f9ec2f;
          .temp-area {
            // background-color: rgba(249, 247, 211, 0.7);
            background-color: #f9ec2f;
            color: black;
          }
          .temp-area,
          input,
          textarea {
            // &:focus,
            &.fill {
              // background-color: rgba(249, 247, 211, 0.7);
              background-color: #f9ec2f;
            }
          }
        }
        &.color-2 {
          background-color: #5daee5;
          .temp-area {
            color: black;
            // background-color: #68bffa;
            background-color: #5daee5;
          }
          .temp-area,
          input,
          textarea {
            // &:focus,
            &.fill {
              // background-color: #68bffa;
              background-color: #5daee5;
            }
          }
        }
        &.color-3 {
          background-color: #9f85b9;
          .temp-area {
            color: black;
            // background-color: #b990e2;
            background-color: #9f85b9;
          }

          .temp-area,
          input,
          textarea {
            // &:focus,
            &.fill {
              // background-color: #b990e2;
              background-color: #9f85b9;
            }
          }
        }
        &.borderless {
          input,
          textarea,
          .temp-area {
            border: none !important;
          }
        }
      }
      .card-settings {
        padding: 0 24px;
        .color-settings {
          .colors-wrapper {
            span {
              width: 36px;
              height: 36px;
              border-radius: 50%;
              margin: 0 8px;
              cursor: pointer;
              position: relative;
              display: inline-block;
              background-color: #f9ec2f;
              transition: all 0.2s;
              &:before {
                transition: all 0.2s;
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-repeat: no-repeat;
                background-position: center;
              }
              &:nth-child(2) {
                background-color: #5daee5;
              }
              &:nth-child(3) {
                background-color: #9f85b9;
              }
              &.active {
                box-shadow: 0px 0px 0px 5px #fff, 0px 0px 0px 7px #f05a22;
                &:before {
                  background-image: url("../../assets/card/active-color.svg");
                }
              }
            }
          }
        }
        .icon-settings {
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 20px;
          .icons-wrapper {
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            max-width: 220px;
            span {
              cursor: pointer;
              display: inline-block;
              width: 41px;
              height: 41px;
              border-radius: 50%;
              background-image: url("../../assets/card/icon1.svg");
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              margin: 6px;
              opacity: 0.2;

              &:nth-child(2) {
                background-image: url("../../assets/card/icon2.svg");
              }
              &:nth-child(3) {
                background-image: url("../../assets/card/icon3.svg");
              }

              &.active {
                opacity: 1;
                box-shadow: 0px 0px 0px 5px #fff, 0px 0px 0px 7px #f05a22;
                &:before {
                  background-image: url("../../assets/card/active-color.svg");
                }
              }
            }
          }
        }
      }
    }
    .card-additional-info {
      resize: none;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      margin: 44px 0px 0 0px;
      height: 117px;
      padding: 10px 16px;
      width: calc(100% - 0px);
      &::placeholder {
        color: #000;
      }

      &.mob {
        display: none;
        width: calc(100% - 32px);
        max-width: 540px;
        margin: 10px 16px 0px;
        background-color: rgba(246, 246, 246, 0.7);
        border: 1px dashed rgba(170, 170, 170, 0.4);
        border-radius: 8px;
      }
      @media only screen and (max-width: 768px) {
        display: none;
        &.mob {
          display: block;
        }
      }
      @media only screen and (max-width: 600px) {
        &.mob {
          max-width: 100%;
        }
      }
    }
    .card-share-btn {
      background-color: #c8c8c8;
      pointer-events: none;
      letter-spacing: 1px;
      border-radius: 8px;
      color: #fff;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-top: 38px;
      border: none;
      padding: 16px 16px 16px 48px;
      position: relative;
      cursor: pointer;
      &.ready {
        pointer-events: all;
        background-color: #f05a22;
      }
      &:before {
        content: "";
        position: absolute;
        left: 16px;
        width: 16px;
        height: 16px;
        background-image: url("../../assets/card/share-icon.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      @media only screen and (max-width: 768px) {
        margin-top: 16px;
      }
    }
    @media only screen and (max-width: 768px) {
      width: 100%;

      .custom-card-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        padding: 16px;
        padding-bottom: 0;
        .title {
          text-align: left;
          @media only screen and (max-width: 370px) {
            font-size: 12px;
          }
        }
        .card-settings {
          width: calc(100% - 0px);
          max-width: 540px;
          padding: 0;
          padding-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          gap: 9px;
          .color-settings {
            flex: 1;
            background-color: rgba(246, 246, 246, 0.7);
            border: 1px dashed rgba(170, 170, 170, 0.4);
            border-radius: 8px;
            padding: 12px;
            .colors-wrapper {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              flex-wrap: nowrap;
              span {
                width: 27px;
                height: 27px;
                &.active {
                  &:before {
                    background-position: top 9px left 3px;
                  }
                }
              }
            }
            @media only screen and (max-width: 370px) {
              padding: 8px;
              .colors-wrapper {
                span {
                  width: 23px;
                  height: 23px;
                  margin: 0px 6px;
                }
              }
            }
          }
          .icon-settings {
            margin-top: 0px;
            flex: 1;
            background-color: rgba(246, 246, 246, 0.7);
            border: 1px dashed rgba(170, 170, 170, 0.4);
            border-radius: 8px;
            padding: 12px;
            .icons-wrapper {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              flex-wrap: nowrap;
              span {
                width: 27px;
                height: 27px;
                margin: 0px 8px;
              }
            }
            @media only screen and (max-width: 370px) {
              padding: 8px;
              .icons-wrapper {
                span {
                  width: 23px;
                  height: 23px;
                  margin: 0px 6px;
                }
              }
            }
          }
          @media only screen and (max-width: 370px) {
            gap: 7px;
          }
        }
      }
    }
    @media only screen and (max-width: 500px) {
      max-width: calc(100% - 20px);
      .custom-card-content {
        .card-preview {
          padding: 16px 24px;
          .temp-area,
          input,
          textarea {
            width: 190px;
          }
        }
      }
    }
  }
}
</style>
