<template>
  <footer class="footer-container">
    <div class="wrapper">
      <div class="footer-wrapper text-font16 eb-font-medium">
        <div class="leftSide">
          <div class="footer-logos-mobile">
            <div>
              <a href="https://europop.ge/" target="_blank">
                <img :src="require('@/assets/footer-component/eb-logo.png')" alt="" />
              </a>
            </div>
            <div class="social">
              <a href="https://instagram.com/europop.ge" target="_blank">
                <img :src="require('@/assets/footer-component/instagram.png')" alt="" />
              </a>
              <a href="https://facebook.com/europop.ge" target="_blank">
                <img :src="require('@/assets/footer-component/facebook.png')" alt="" />
              </a>
              <a href="https://youtube.com/@europop_" target="_blank">
                <img :src="require('@/assets/footer-component/youtube.png')" alt="" />
              </a>
            </div>
          </div>
          <div class="copyright ">ყველა უფლება დაცულია © 2024</div>
        </div>
        <div class="rightSide" @click="backToTop()">
          <router-link :to="{name: 'main'}"> <img :src="require('@/assets/footer-component/main-logo.svg')" alt="" /> </router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import router from "../../router";
export default {
  name: "FooterComponent",
  props: {},
  methods: {
      backToTop() {
      var VueScrollTo = require("vue-scrollto");
      var options = {
        container: ".main-wrapper",
        easing: "ease-in",
        lazy: false,
        offset: 0,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      };
      var cancelScroll1 = VueScrollTo.scrollTo("#app", 100, options);
    },
  },
};
</script>

<style lang="scss">
.footer-container {
  background-color: #fff;
  padding: 43px 0px;
  margin-bottom: 0px;
  margin-top: 86px;
  color: #000;
  .footer-logos-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 768px) {
      flex-direction: row;
    }
  }
  @media only screen and (max-width: 1366px) {
    padding: 43px 0px;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 768px) {
    padding: 31px 0px;
    margin-top: 40px;
    .rightSide {
      display: none;
    }
  }
  .footer-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .leftSide {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    width: 100%;
    img {
      max-width: 151px;
    }
    div {
      width: 100%;
      &.copyright {
        color: #000;
        text-align: left;
      }
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      img {
        max-width: 151px;
      }
      div {
        width: 100%;
        &.copyright {
          text-align: left;
          font-size: 13px;
          color: #848484;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      img {
        max-width: 121px;
      }
    }
    .social {
      margin: 20px 0;
      @media only screen and (max-width: 768px) {
        margin: 0px 0;
        display: flex;
        justify-content: flex-end;
      }

      img {
        margin-right: 7px;
      }
    }
  }
}
</style>
