<template>
  <section class="stats-popup-wrapper">
    <div class="stats-popup-box">
      <div class="close-btn" @click="CloseStatsPopUp"></div>
      <div class="header">
        <p class="title text-font16 eb-font-bold text-uppercase">კალენდარი</p>
        <div class="select-month text-font16 eb-font-medium text-uppercase">
          <v-select :options="months" v-model="selectedMonthData" :reduce="(option) => option.id" label="text"></v-select>
        </div>
      </div>
      <div class="body text-font16 eb-font-bold">
        <div class="popup_stages_table" v-if="FilteredData.length > 0">
          <div class="popup_stages_table-inner">

            <div class="popup_stages_teams" v-for="(item, i) in FilteredData" :key="i">

              <div class="popup_stages_group" v-for="(group, i) in item.groups" :key="i">

                <div class="popup_stages_team" v-for="(game, i) in group.games" :key="i">
                  <div class="game-info">
                    <div class="teamA text-uppercase eb-font-bold">{{ game.firstTeam ? generateTeamnames(game.firstTeam) : "-" }}</div>
                    <img :src="`${game.firstTeam.imageUrl}`" />
                    <div class="scores_time eb-font-roman text-font16">{{ moment(game.dateTime).format("HH:mm") }}</div>
                    <img :src="`${game.secondTeam.imageUrl}`" />
                    <div class="teamB text-uppercase eb-font-bold">{{ game.secondTeam ? generateTeamnames(game.secondTeam) : "-" }}</div>
                  </div>
                  <div class="game-time eb-font-roman text-font14">
                    <p>{{ item.date ? moment(item.date).format("dddd DD MMMM") : "-" }}</p>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
        <div v-else class="no-games">
          <p>ამ თვეში თამაშები არ არის</p>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
 import moment from "moment";
export default {
  name: "StatsPopup",
   props:{
  StatsData: Array,
  months: Array,
  selectedMonth: Number,
  isXSWidth: Boolean
 },
 data(){
  return {
    selectedMonthData: 0,
  }
 },

 computed:{
  FilteredData(){
      // return this.StatsData.filter(x=>x.groups.every(q=>q.groupName=="B" || q.groupName=="A"));

switch (this.selectedMonthData) {
  case 0:
    return this.StatsData.filter(x => moment(x.date).month() == 0);
  case 1:
    return this.StatsData.filter(x => moment(x.date).month() == 1);
  case 2:
    return this.StatsData.filter(x => moment(x.date).month() == 2);
  case 3:
    return this.StatsData.filter(x => moment(x.date).month() == 3);
  case 4:
    return this.StatsData.filter(x => moment(x.date).month() == 4);
  case 5:
    return this.StatsData.filter(x => moment(x.date).month() == 5);
  case 6:
    return this.StatsData.filter(x => moment(x.date).month() == 6);
  case 7:
    return this.StatsData.filter(x => moment(x.date).month() == 7);
  case 8:
    return this.StatsData.filter(x => moment(x.date).month() == 8);
  case 9:
    return this.StatsData.filter(x => moment(x.date).month() == 9);
  case 10:
    return this.StatsData.filter(x => moment(x.date).month() == 10);
  case 11:
    return this.StatsData.filter(x => moment(x.date).month() == 11);

  default:
    break;
}

      return this.StatsData;
    },
 },
  methods: {
    CloseStatsPopUp(){
     
      this.$emit("closeStatsPopUp")
    },
     StatsPopUpEvent(){
       this.$emit("openStatsPopUp");
 
    },
   
    generateTeamnames(num) {
     if (num.name) {
      return !this.isXSWidth ? num.name : num.name.slice(0, 3);
    }
    },
  
  },
   watch: {
    selectedMonthData: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        console.log("selectedMonthData", this.selectedMonthData);
        this.$emit(
          "MonthRequest",
          this.selectedMonthData
        );
      }
    },
  },
  beforeMount(){
  this.selectedMonthData=this.selectedMonth;
},
  mounted(){

  }
};
</script>

<style lang="scss">
.stats-popup-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(45, 45, 45, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;

  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px !important;
  }

  .stats-popup-box {
    width: 100%;
    max-width: 973px;
    background-color: #fff;
    border-radius: 16px;
    padding: 30px;
    padding-bottom: 20px;
    position: relative;
    color: #000;

    .close-btn {
      position: absolute;
      width: 48px;
      height: 48px;
      background: url("../../assets/stats-component/stats-popup-component/stats-popup-close-btn.png")
        no-repeat center;
      background-size: contain;
      cursor: pointer;
      top: -24px;
      right: -16px;
      @media only screen and (max-width: 600px) {
        width: 26px;
        height: 26px;
        top: -11px;
        right: -8px;
      }
    }
    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      margin-bottom: 20px;
      .title {
        flex: 1.5;
        display: flex;
        justify-content: center;
        align-items: center;
        // text-align: center;
        color: #000;
        border-radius: 8px;
        border: 1px solid rgba(236, 236, 236, 0.7);
        background: #fdfdfd;
        height: 100%;
        margin-right: 10px;
        @media only screen and (max-width: 768px) {
          margin-right: 4px;
          font-size: 12px;
          font-family: "Helvetica-Roman" !important;
          text-transform: unset;
          -moz-font-feature-settings: "case";
          -webkit-font-feature-settings: "case";
          font-feature-settings: "case" off;
        }
      }
      .select-month {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #787878;
        border-radius: 8px;
        border: 1px solid rgba(236, 236, 236, 0.7);
        background: #fdfdfd;
        height: 100%;
        margin-left: 10px;
        padding: 0;
        cursor: pointer;
        position: relative;
        .v-select {
          cursor: pointer;
          width: 100%;
        }
        @media only screen and (max-width: 768px) {
          flex: 2;
          margin-left: 4px;
          font-size: 12px;
        }
      }
      @media only screen and (max-width: 600px) {
        height: 40px;
        margin-bottom: 10px;
      }
    }
    .body {
      min-height: 200px;
      .popup_stages_table {
        width: 100%;
        padding: 24px 24px 20px 24px;
        background-color: rgba(249, 249, 249, 0.3);
        border: 1px solid rgba(236, 236, 236, 0.7);
        border-radius: 8px;

        .popup_stages_table-inner {
          overflow: hidden;
          overflow-y: auto;
          max-height: 470px;
          padding-right: 14px;

          @-moz-document url-prefix() {
            scrollbar-width: thin;
            scrollbar-color: #f15b25 transparent;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #f15b25;

            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }

          &::-webkit-scrollbar {
            width: 8px !important;
          }
          @media only screen and (max-width: 600px) {
            padding-right: 7px;
            &::-webkit-scrollbar {
              width: 5px !important;
            }
          }
        }
        .popup_stages_group {
        }
        .popup_stages_teams {
          &:first-child {
            border: none;
            padding-top: 0;
          }
          .popup_stages_team {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: rgba(239, 239, 239, 0.2);
            border: 1px solid rgba(236, 236, 236, 0.6);
            border-radius: 8px;
            min-height: 74px;
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 10px;
            @media only screen and (max-width: 600px) {
              padding-left: 15px;
              padding-right: 15px;
            }
          }
          .game-info {
            width: calc(50% - 20px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 20px;

            .teamA,
            .teamB {
              min-width: 100px;
              max-width: 100px;
              font-size: 16px;
              @media only screen and (max-width: 768px) {
                font-size: 12px;
                min-width: 70px;
                max-width: 70px;
                font-family: "Helvetica-Roman" !important;
              }
              @media only screen and (max-width: 600px) {
                font-size: 10px;
                min-width: 50px;
                max-width: 50px;
              }
              @media only screen and (max-width: 500px) {
                min-width: 30px;
                max-width: 30px;
              }
            }

            .teamB {
              text-align: right;
              @media only screen and (max-width: 500px) {
                text-align: left;
              }
            }
            img {
              width: 34px;
              height: auto;
              margin: 0 12px;
              @media only screen and (max-width: 768px) {
                width: 16px;
                margin: 0 6px;
              }
            }
            .scores_time {
              height: 32px;
              border: 1px solid #8e8e8e;
              padding: 5px 10px;
              display: flex;
              align-items: center;
              border-radius: 16px;
              margin: 0 20px;
              @media only screen and (max-width: 768px) {
                font-size: 11px;
                height: 20px;
                margin: 0 14px;
              }
              @media only screen and (max-width: 600px) {
                margin: 0 0px;
              }
            }
            .stages_scores {
              background-color: #4a6547;
              color: #fff;
              height: 32px;
              display: flex;
              padding: 5px 10px;
              border: 1px solid #eae8e8;
              .teamA_score,
              .teamB_score {
                line-height: 1.4;
              }
              .score_line {
                height: 20px;
                width: 1px;
                background-color: #fff;
                display: block;
                margin: 0 10px;
              }
            }
            @media only screen and (max-width: 768px) {
              width: auto;
              margin-right: 0px;
            }
          }
          .game-time {
            width: calc(50% - 20px);
            margin-left: 20px;
            text-align: right;
            color: #232323;
            @media only screen and (max-width: 992px) {
              width: auto;
              max-width: 100px;
            }
            @media only screen and (max-width: 768px) {
              width: auto;
              max-width: 67px;
              font-size: 10px;
            }
            @media only screen and (max-width: 600px) {
              max-width: 67px;
            }
          }

          .popup_stages_date {
            span {
              margin-right: 5px;
            }
          }
        }
        @media only screen and (max-width: 600px) {
          padding: 0;
          background-color: transparent;
        }
        @media only screen and (max-width: 370px) {
          max-height: 60vh;
        }
      }
      .no-games {
        padding: 20px;
        text-align: center;
      }
    }

    @media only screen and (max-width: 992px) {
      width: 100%;
      max-width: 95%;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      max-width: 96%;
    }
    @media only screen and (max-width: 600px) {
      max-width: calc(100% - 20px);
      padding: 10px;
    }
  }
}
</style>
