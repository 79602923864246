<template>
  <div class="tc-row" :class="{ open: isActive }">
    <div class="tc-row-head font-hl-medium" @click="toggle">
      {{ title }}
      <div class="icon">
        <img v-if="!isActive" :src="require('@/assets/terms-component/Vector.png')" alt="" />
        <img v-if="isActive" :src="require('@/assets/terms-component/close.png')" alt="" />
      </div>
    </div>
    <div class="tc-row-content">
      <ul class="tc-ul font-hl-roman">
        <slot></slot>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  // ...
  name: "AccordionComponent",
  data() {
    return {
      isActive: false,
    };
  },
  props: ["title"],

  methods: {
    toggle() {
      this.isActive = this.isActive ? false : true;
    },
  },
};
</script>
<style lang="scss">
.tc-section {
  .tc-row {
    margin-bottom: 16px;
    cursor: pointer;
    color: #000;
    background-color: rgba(251, 251, 251, 0);
    border-radius: 12px !important;

    .tc-row-head {
      background-color: #fff;
      font-size: 20px !important;
      border-radius: 12px !important;
      position: relative;
      line-height: 1.2;
      color: #000;
      padding: 40px;
      .icon {
        position: absolute;
        right: 20px;
        margin: auto;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
      }
      @media only screen and (max-width: 768px) {
        font-size: 14px !important;
        padding: 24px 20px !important;
      }
    }

    .tc-row-content {
      margin-top: 0;
      margin-bottom: 0px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      max-height: 0;
      overflow: hidden;
      transition: opacity 0.4s, max-height 0.6s;
      color: #6a6a6a;
      opacity: 0;
      line-height: 1.4;
      .tc-ul {
        list-style: none;
        list-style-type: none !important;
        padding: 0px 40px 40px 40px;

        @media only screen and (max-width: 768px) {
          padding: 0px 20px 20px;
          //  margin-top: 16px;
        }

        p {
          color: #ee5a22;
        }
        li {
          position: relative;
          margin-bottom: 10px;

          ul {
            list-style: none;
            margin-top: 5px;
            margin-bottom: 20px;
            padding-left: 15px;

            li {
              position: relative;
              margin-bottom: 5px;
              &:before {
                content: "";
                position: absolute;
                left: -15px;
                top: 7px;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #bbbbbb;
              }
            }
          }
          &:before {
            content: "";
            position: absolute;
            left: -15px;
            top: 7px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #bbbbbb;
          }
          &.none{
            &:before{
              background-color: #ee5a22;
            }
          }
        }
      }
      @media only screen and (max-width: 768px) {
        //margin-top: 14px;
      }
    }

    &.open {
      background-color: #fff;
      .tc-row-head {
        color: #f46a21;
        //padding-bottom: 0;
      }
      .tc-row-content {
        max-height: 3000px;
        opacity: 1;
        margin-bottom: 16px;

        ul {
          background-color: #fff;
          border-radius: 12px;
        }
        @media only screen and (max-width: 768px) {
          margin-bottom: 14px;
        }
      }
    }
  }
}
</style>
