<template>
  <div class="special-cards stats-box">
    <h3 class="isTitle eb-font-bold text-font20 text-uppercase">3 განსაკუთრებული ბარათი</h3>
    <!-- <div class="hr"></div> -->

    <div class="isContent">
      <div class="cards-outer">
        <div class="cards-wrapper">
          <div v-for="(slide, i) in slidesArray" :key="i" class="card-slide" :class="[`card-slide-${slide.id}`, {'slide-active': isCardCurrentSlide == i}]" @click="updateSlide(i)">
            <div class="inner">
              <img :src="require(`../../assets/home-component/card.png`)" class="card-inner" />
              <div class="slide-img">
                <img :src="require(`../../assets/home-component/${slide.img}`)" />
              </div>
            </div>
          </div>
          <div class="cards-btns cards-button-prev" :class="{'button-disabled': isCardCurrentSlide == 0}" @click="updateSlidePrev()"></div>
          <div class="cards-btns cards-button-next" :class="{'button-disabled': isCardCurrentSlide == 3}" @click="updateSlideNext()"></div>
        </div>
        <!-- <div class="cards-btns cards-button-prev"></div>
        <div class="cards-btns cards-button-next"></div> -->
        <div class="card-slide-text-wrapper">
          <div class="card-slide-text eb-font-black text-font16" v-for="(slide, i) in slidesArray" :key="i" :class="[`card-slide-text-${slide.id}`, {show: isCardCurrentSlide == i}]">
            <h3 class="eb-font-black text-font24 text-uppercase"> {{slide.text.title}}</h3>
            <p class="eb-font-medium text-font16"> {{slide.text.desc}}</p>
            <p class="eb-font-medium text-font16" v-if="slide.id == 4">
              <button class="cards-btn eb-font-medium" @click="openCardPopup()">ბარათის შექმნა</button>
            </p>
          </div>
        </div>
      </div>

      <div class="cards-swiper-outer">
        <swiper ref="cardsSwiper" :options="cardsSwiperOptions" class="cards-swiper-wrapper" @slideChange="showText($event)">
          <swiper-slide ref='cardsSlides' v-for="(slide, i) in slidesArray" :key="i" class="cards-slide" :class="`slide-${slide.id}`">
            <div class="inner">
              <div class="slide-img">
                <img :src="require(`../../assets/home-component/${slide.img}`)" />
              </div>
            </div>
          </swiper-slide>

        </swiper>
        <div class="cards-swiper-btns cards-swiper-button-prev swiper-button-prev" slot="button-prev"></div>
        <div class="cards-swiper-btns cards-swiper-button-next swiper-button-next" slot="button-next"></div>
        <div class="cards-swiper-pagination swiper-pagination" slot="pagination"></div>
        <div class="slide-text eb-font-black text-font16" v-for="(slide, i) in slidesArray" :key="i" :class="{show: isCurrentSlide == i}">
          <h3 class="eb-font-bold text-font24"> {{slide.text.title}}</h3>
          <p class="eb-font-medium text-font16"> {{slide.text.desc}}</p>
          <p class="eb-font-medium text-font16" v-if="slide.id == 4">
            <button class="cards-btn eb-font-medium" @click="openCardPopup()">ბარათის შექმნა</button>
          </p>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
export default {
  name: "SpecialCardsComponent",
  props: {
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      slidesArray: [
           {
          id: "1",
          img: "slides/new/slide-buy.png",
          text: {
            title: "კარგაად!",
            desc: "მომდევნო 2 წუთის განმავლობაში მეტოქის გუნდიდან შესაძლებელია ნებისმიერი ერთი მოთამაშის ჩანაცვლება",
          },
          showText: false,
        },
        {
          id: "2",
          img: "slides/new/slide-add-time.png",
          text: {
            title: "დამატებითი დრო",
            desc: "ტაიმს ემატება 5 წუთი",
          },
          showText: false,
        },
      
        {
          id: "3",
          img: "slides/new/slide-without-wall.png",
          text: {
            title: "კედლის გარეშე",
            desc: "მომდევნო 2 წუთის განმავლობაში, გუნდი, მოწინააღმდეგის ნახევარზე დანიშნულ ჯარიმას, ურტყამს ცოცხალი კედლის გარეშე",
          },
          showText: false,
        },
      
        {
          id: "4",
          img: "slides/new/slide-create-card.png",
          text: {
            title: "შექმენი შენი ბარათი",
            desc: ``,
          },
          showText: false,
        },
      ],
      isCurrentSlide: 0, 
      isCardCurrentSlide: 0, 
    };
  },
  computed: {
    cardsSwiperOptions() {
      return {
        effect: "coverflow", 
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        spaceBetween: 0,
       slideToClickedSlide: true,
        loop: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
           depth: 150,
           modifier: 3,
           scale: 1,
          slideShadows: false,
        },
        pagination: {
          el: ".cards-swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".cards-swiper-button-next",
          prevEl: ".cards-swiper-button-prev",
        },
         breakpoints: {
       //   400: {
       //    coverflowEffect: {
          // rotate: 0,
          // stretch: 0,
          // depth: 100,
          //  modifier: 2.8,
          // scale: 1,
        // slideShadows: false,
     //   },
     //     },
        },

     };
    },
  },
  methods: {
    openCardPopup() {
     this.$emit("ToggleCustomCard");
    },

    showText(e) {
      this.isCurrentSlide = e.realIndex;
    },
    updateSlide(v) {
      console.log(v);
      this.isCardCurrentSlide = v;
    },
    updateSlidePrev() {
      if( this.isCardCurrentSlide > 0){
        this.isCardCurrentSlide--;
      } else {
        this.isCardCurrentSlide = 0; }
      
       console.log( 'isCardCurrentSlide', this.isCardCurrentSlide);
    },
    updateSlideNext(v) {
       if( this.isCardCurrentSlide < 3){
       this.isCardCurrentSlide++;
      } else {
         this.isCardCurrentSlide = 3;
      }
      
         console.log( 'isCardCurrentSlide', this.isCardCurrentSlide);
    },

  },
  watch: {
  
  },
  mounted() {
    
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/eb-mixin.scss";
.special-cards {
  border: 2px solid #fff;
  background: url("../../assets/card-bg.png") no-repeat center;
  background-size: cover;
  background-position: top left;
  border-radius: 10px;
  height: 665px;
  min-height: auto;
  width: calc(78% - 36px);
  margin-left: 36px;

  .isTitle {
    padding-bottom: 24px;
    padding-top: 24px;
    border-bottom: 1px solid #fff;
  }
  .isContent {
    padding-top: 70px;
    padding-bottom: 55px;
    @include interpolate(padding-left, 1366px, 1720px, 24px, 64px);
    @include interpolate(padding-right, 1366px, 1720px, 24px, 64px);
  }
  .cards-outer {
    width: calc(100% - 0px);
    margin-left: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 54px;
    padding-right: 54px;
    @media only screen and (max-width: 992px) {
      padding-left: 24px;
      padding-right: 24px;
    }
    @media only screen and (max-width: 768px) {
      padding-left: 24px;
      padding-right: 24px;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .cards-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    .card-slide {
      flex: 1;
      // min-height: 324px;
      margin-left: 12px;
      margin-right: 12px;
      min-width: unset;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      .inner {
        position: relative;
        height: 100%;
        border-radius: 20px;
        padding: 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .card-inner {
          position: relative;
          display: block;
          max-width: 100%;
          object-fit: cover;
          height: auto;
          width: 100%;
          border-radius: 20px;
          z-index: -1;
        }
        .slide-img {
          position: absolute;
          border-radius: 12px;
          max-width: 124px;
          height: auto;

          img {
            display: block;
            max-width: 100%;
            object-fit: cover;
            height: auto;
            width: 100%;
            border-radius: 12px;
          }
        }
      }
      &.card-slide-1 {
        .inner {
          background-color: #232323;
        }
      }
      &.card-slide-2 {
        .inner {
          background-color: #3b8f20;
          .slide-img {
            border-radius: 12px;
            height: auto;
          }
        }
      }

      &.card-slide-3 {
        .inner {
          background-color: #51227d;
        }
      }

      &.card-slide-4 {
        .inner {
          background-color: #878787;
        }
      }
      &.slide-active {
        transform: scale(1.1);
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25), 0 0 0 2px #f05a22;
        border-radius: 20px;
        .inner {
          overflow: visible;
        }
      }
      @media only screen and (max-width: 1200px) {
        min-height: 180px;
      }
      @media only screen and (max-width: 768px) {
        min-height: unset;
      }
    }
  }
  .cards-btns {
    position: absolute;
    top: 45%;
    z-index: 999;
    &.cards-button-prev {
      left: -54px;
      border-radius: 50%;
      width: 35px !important;
      height: 35px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 35px !important;
      display: inline-block;
      background: url("../../assets/home-component/cards-slider-btn.png")
        no-repeat center;
      background-size: contain;
      transform: rotate(180deg);
      @media only screen and (max-width: 768px) {
        left: -40px;
      }
    }
    &.cards-button-next {
      right: -54px;
      border-radius: 50%;
      width: 35px !important;
      height: 35px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 35px !important;
      display: inline-block;
      background: url("../../assets/home-component/cards-slider-btn.png")
        no-repeat center;
      background-size: contain;
      @media only screen and (max-width: 768px) {
        right: -40px;
      }
    }
    &:hover {
      cursor: pointer;
    }
    &.button-disabled {
      &.cards-button-prev {
        background: url("../../assets/home-component/cards-slider-btn-disabled.png")
          center no-repeat;
        background-size: contain;
        transform: rotate(0deg);
      }
      &.cards-button-next {
        background: url("../../assets/home-component/cards-slider-btn-disabled.png")
          center no-repeat;
        background-size: contain;
        transform: rotate(-180deg);
      }
    }
  }

  .card-slide-text-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 150px;
  }
  .card-slide-text {
    color: #000;
    display: none;
    margin-top: 35px;
    flex: 1;
    max-width: 40%;

    h3 {
      color: #de5b37;
      margin-bottom: 16px;
      line-height: 1.4;
    }
    p {
      color: #232323;
      line-height: 20px;
      @media only screen and (max-width: 1200px) {
        font-size: 13px;
      }
    }
    .cards-btn {
      background-color: #f04e23;
      color: #fff;
      padding: 16px 40px;
      border-radius: 8px;
      border: none;
      outline: none;
      font-size: 14px;
      cursor: pointer;
      @media only screen and (max-width: 992px) {
        padding: 10px 24px;
        font-size: 12px;
      }
    }
    &.show {
      display: block;
    }
    &.card-slide-text-1 {
      margin-left: -8.5%;
    }
    &.card-slide-text-2 {
      margin-left: 17.5%;
    }
    &.card-slide-text-3 {
      margin-left: 42%;
    }
    &.card-slide-text-4 {
      margin-left: 75%;
      margin-top: 30px;
      h3 {
        color: #000;
        margin-bottom: 15px;
        line-height: 1;
      }
    }
    @media only screen and (max-width: 1200px) {
      margin-top: 24px;
      h3 {
        font-size: 18px;
        text-transform: unset;
        -moz-font-feature-settings: "case";
        -webkit-font-feature-settings: "case";
        font-feature-settings: "case" off;
      }
      p {
        font-size: 14px;
      }
    }
  }

  //// cards-swiper
  .cards-swiper-outer {
    width: calc(100% - 0px);
    max-width: 331px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
    }
  }

  .cards-swiper-wrapper {
    width: 100%;
    position: relative;
    // min-height: 502px;
    .swiper-slide {
      &.cards-slide {
        min-width: unset;
        display: flex;
        flex-direction: column;
        max-width: 152px;
        .inner {
          position: relative;
          overflow: hidden;
          border-radius: 12px;
          // height: 364px;
          height: 208px;
          padding: 29% 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .slide-img {
            border-radius: 12px;
            height: auto;
            width: 100px;
            height: 99px;
            img {
              display: block;
              max-width: 100%;
              // object-fit: cover;
              height: auto;
              width: 100%;
              border-radius: 12px;
            }
          }
        }
        &.slide-1 {
          .inner {
            background-color: #232323;
          }
        }
        &.slide-2 {
          .inner {
            background-color: #3b8f20;
            .slide-img {
              border-radius: 12px;
              height: auto;
            }
          }
        }

        &.slide-3 {
          .inner {
            background-color: #51227d;
          }
        }

        &.slide-4 {
          .inner {
            background-color: #878787;
          }
        }
        &.swiper-slide-active {
          // transform: scale(1.1) !important;
          .inner {
            overflow: visible;
          }
        }
      }
    }
  }
  .cards-swiper-pagination {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
    position: relative;
    .swiper-pagination-bullet {
      display: inline-block;
      margin: 0px 2px;
      background-color: #bcbcbc;
      width: 8px;
      height: 8px;
      opacity: 1;
      border-radius: 50%;
      &.swiper-pagination-bullet-active {
        background-color: #de5a35;
      }
    }
  }
  .cards-swiper-btns {
    position: absolute;
    top: 230px;
    z-index: 999;
    &.cards-swiper-button-prev {
      left: 25% !important;
      border-radius: 50%;
      width: 25px !important;
      height: 25px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 35px !important;
      display: inline-block;
      background: url("../../assets/home-component/cards-slider-btn.png")
        no-repeat center;
      background-size: contain;
      transform: rotate(180deg);
    }
    &.cards-swiper-button-next {
      right: 25% !important;
      border-radius: 50%;
      width: 25px !important;
      height: 25px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 25px !important;
      // span {
      display: inline-block;
      background: url("../../assets/home-component/cards-slider-btn.png")
        no-repeat center;
      background-size: contain;
      // }
    }
    &:hover {
      cursor: pointer;
    }
    &.swiper-button-disabled {
      &.cards-swiper-button-prev {
        background: url("../../assets/home-component/cards-slider-btn-disabled.png")
          center no-repeat;
        background-size: contain;
        transform: rotate(0deg);
      }
      &.cards-swiper-button-next {
        background: url("../../assets/home-component/cards-slider-btn-disabled.png")
          center no-repeat;
        background-size: contain;
        transform: rotate(-180deg);
      }
    }
  }
  .slide-text {
    color: #000;
    display: none;
    margin-top: 0;
    h3 {
      color: #de5b37;
      margin-bottom: 18px;
      line-height: 1.4;
    }
    p {
      color: #232323;
      line-height: 20px;
    }
    .cards-btn {
      background-color: #f04e23;
      color: #fff;
      padding: 16px 40px;
      border-radius: 8px;
      border: none;
      outline: none;
      font-size: 14px;
      cursor: pointer;
    }
    &.show {
      display: block;
    }
    @media only screen and (max-width: 768px) {
      h3 {
        font-size: 18px;
      }
      p {
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 1365px) {
    // padding: 24px 64px 30px;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    height: auto;
    min-height: auto;
    .isContent {
      padding-top: 55px;
      padding-bottom: 55px;
      padding-left: 64px;
      padding-right: 64px;
    }
  }
  @media only screen and (max-width: 768px) {
    background: url("../../assets/home-component/cards-box-bg-tab.png")
      no-repeat;
    background-position: top -8px left 0px;
    background-size: 768px;
    border: 3px solid #fff;
    border-radius: 10px;
    padding: 24px 11px 30px;
    .isTitle {
      font-size: 14px;
      text-transform: unset;
      -moz-font-feature-settings: "case";
      -webkit-font-feature-settings: "case";
      font-feature-settings: "case" off;
    }
  }
  @media only screen and (max-width: 600px) {
    background: url("../../assets/home-component/cards-box-bg-mob2.png")
      no-repeat;
    background-position: top -8px left 0px;
    background-size: 600px;
  }
  @media only screen and (max-width: 400px) {
    background: url("../../assets/home-component/cards-box-bg-mob.png")
      no-repeat;
    background-position: top -3px left 0px;
    background-size: 355px;
  }
}
</style>






