import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";
import TeamsComponent from "@/views/teams/index.vue";
import GalleryComponent from "@/views/gallery/index.vue";
import PlayersComponent from "@/components/players/index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "main",
    component: Home,
    props: true,
  },
  {
    path: "/teams",
    name: "teams",
    component: TeamsComponent,
  },
  {
    path: "/teams/:slug",
    name: "players",
    component: PlayersComponent,
    props: true,
  },
  {
    path: "/gallery",
    name: "gallery",
    component: GalleryComponent,
  },
  // {
  //   path: "/fantasy",
  //   name: "fantasy",
  //   component: Home,
  //   beforeEnter(to, from, next) {
  //     window.open('https://fantasy.youtubelegends.ge/', '_blank');
  //     next()
  //   }
  // },

  {
    path: '/*',
    redirect: { name: "main" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      //  const el = window.location.href.split('#')[1]
      const el = to.hash.substring(1);
      if (el.length) {
        setTimeout(() => {
          document.getElementById(el)?.scrollIntoView({ behaviour: "smooth" })
        }, 1000)

      }
    } else if (SavedPosition) {
      return SavedPosition
    }
    else {
      // console.log(document.querySelector('.main-wrapper')?.scrollIntoView());
      document.getElementById('app')?.scrollIntoView({ behaviour: "smooth" })
    }

  }
});

export default router;
