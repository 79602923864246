<template>
  <div class="main-component">
    <div class="header" id="homeHeader" :class="{isLiveTime: IsLiveTime}">
      <div class="header-bg"></div>
      <div class="wrapper">
        <template v-if="!IsLiveTime">
          <div class="header-inner">
            <Countdown v-bind="countdownObj"></Countdown>
            <div class="header-text text-uppercase">
              <p class="isTitle eb-font-bold"><img :src="require('@/assets/home-component/home-header-title-img.png')" alt=""></p>
              <p class="isDesc eb-font-black ">ფეხბურთი ახალი წესებით</p>
            </div>
          </div>
        </template>

        <template v-else>
          <VideoComponent :id="videoId"></VideoComponent>
        </template>
      </div>
    </div>
    <div class="main-page">
      <div class="wrapper">
        <div class="offer-wrapper eb-font-bold">
          <div class="offer-box offer-box-1">
            <img :src="require('@/assets/home-component/offer-icon1.png')" alt="" />
            <p>21 ვარსკვლავური<br /> იუთუბ გუნდი</p>
          </div>
          <div class="offer-box offer-box-2">
            <img :src="require('@/assets/home-component/offer-icon2.png')" alt="" />
            <p><a href="https://www.youtube.com/@EuropopBuzz " target="_blank" class="txt-black">ყველა მატჩის <br /> ლაივი</a></p>
          </div>
          <div class="offer-box offer-box-3">
            <img :src="require('@/assets/home-component/offer-icon3.png')" alt="" />
            <p>და ბარათები, რომლებიც მატჩის მიმდინარეობას თავდაყირა დააყენებს</p>
          </div>
        </div>
        <div class="stats-wrapper">
          <StatsComponent :StatsData="StatsData" @openStatsPopUp="OpenStatsPopUp"></StatsComponent>
          <SpecialCardsComponent @ToggleCustomCard="ToggleCustomCard"></SpecialCardsComponent>
          <WinnerSurveyComponent @ChooseWinnerTeam="ChooseWinnerTeam" :WinnerSurveyData="WinnerSurveyData"></WinnerSurveyComponent>
          <ResultsSurveyPosterComponent></ResultsSurveyPosterComponent>
        </div>

        <div class="post-carousel-wrapper" :class="{'add-margin': HomeVideoList.length > 0}">
          <p class="title eb-font-black text-font24" v-if="HomeVideoList.length > 0">ვიდეოები და სტატიები</p>
          <VideosCarouselComponent :HomeVideoList="HomeVideoList"></VideosCarouselComponent>
        </div>
      </div>
    </div>
    <TermsWrapper id="terms">
      <div class="wrapper">
        <AccordionComponent v-bind:title="'ჩემპიონატის მიზნები, ამოცანები, რეპორტაჟები'">
          <li class="none">ჩემპიონატი მიზნად ისახავს:
            <ul>
              <li>ჯანსაღი ცხოვრების წესის წახალისებას</li>
              <li>სამოყვარულო მინი ფეხბურთის განვითარებასა და პოპულარიზაციას</li>
              <li>ჩემპიონთა და პრიზიორთა განსაზღვრას</li>
              <li>YT Legends League 2024 არის საფეხბურთო ტურნირი შოუს ელემენტებით</li>
              <li>ყველა მატჩის პირდაპირი რეპორტაჟი შედგება <a href="https://www.youtube.com/@EuropopBuzz" target="_blank" class="txt-gray">europop buzz-ის</a> იუთუბ არხზე</li>
            </ul>
          </li>
        </AccordionComponent>
        <AccordionComponent v-bind:title="'მონაწილეები, ვადები და ჩატარების პირობები'">
          <li>ჩემპიონატის ჩატარების ვადები განისაზღვრება 2024 წლის 16 თებერვლიდან, 2024 წლის 8 ივნისის ჩათვლით </li>
          <li>ტურნირში მონაწილეობას მიიღებს 21 წინასწარ შერჩეული გუნდი</li>
          <li>მოთამაშეს მაისურზე მკერდის მარცხენა მხარეს უნდა ჰქონდეს საკუთარი კლუბის ლოგო, რომელიც დამოწმებულია სეზონისწინა განაცხადის მიღებისას</li>
          <li>მატჩის სათამაშო დრო განისაზღვრება ორი 20 წუთიანი ტაიმით (40 წუთი ჭუჭყიანი დრო), შესვენება არაუმეტეს 5 წუთის</li>
          <li>21 გუნდი, წილისყრის პრინციპით, განთესვის გარეშე, გადანაწილდებიან A, B, C და D ჯგუფებში, გუნდები ერთმანეთს წრიული სისტემით, ერთ ეტაპად დაუპირისპირდებიან</li>
          <li>წრიული ეტაპის დასრულების შემდეგ გუნდები, სატურნირო ცხრილებში დაკავებული ადგილების მიხედვით (თითო ჯგუფიდან 4 საუკეთესო შედეგის მქონე გუნდი), წილისყრის სისტემით, ერთ მატჩიან პლეი-ოფში (1/8 ფინალი) განაგრძობენ თამაშს</li>
          <li>წილისყრის შედეგად ჯგუფების გამარჯვებულები მეოთხე ადგილებზე გასულებს, ხოლო მეორე ადგილებზე გასულები მესამე ადგილებზე გასულებს შეხვდებიან</li>
          <li>ტურნირში მოქმედებს ბარათების სპეციალური სისტემა, რომელსაც თამაშის მიმდინარეობის და წესების შეცვლა შეუძლია, ერთ გუნდს შეუძლია ერთი ბარათის მხოლოდ ერთხელ გამოყენება:
            <ul>
              <li><span class="eb-font-bold">დამატებითი დრო </span> - ტაიმს ემატება 5 წუთი.</li>
              <li><span class="eb-font-bold">კედლის გარეშე </span> - მომდევნო 2 წუთის განმავლობაში, გუნდი, მოწინააღმდეგის ნახევარზე დანიშნულ ჯარიმას, ურტყამს ცოცხალი კედლის გარეშე</li>
              <li><span class="eb-font-bold">კარგაად! </span> - 2 წუთის განმავლობაში მეტოქის გუნდიდან შესაძლებელია ნებისმიერი ერთი მოთამაშის ჩანაცვლება</li>
            </ul>
          </li>
          <li>მატჩის დროს შეცვლების განხორციელების რაოდენობა შეზღუდული არ არის; შეცვლის პროცედურის დარღვევის შემთხვევაში მსაჯი ვალდებულია გააფრთხილოს ყვითელი ბარათით დამრღვევი მოთამაშე</li>
        </AccordionComponent>
        <AccordionComponent v-bind:title="'გამარჯვებულის გამოვლენა'">
          <li class="none">ჯგუფურ ეტაპზე გუნდების მიერ დაკავებული ადგილები განისაზღვრება მათ მიერ ჩემპიონატის განმავლობაში დაგროვებული ქულების მიხედვით. გამარჯვებისთვის გუნდებს ენიჭებათ - 3 ქულა, ფრეზე - 1 ქულა, წაგებისთვის - 0 ქულა.
            თანაბარი ქულების შემთხვევაში სატურნირო ცხრილში ადგილები განისაზღვრება შემდეგი პრიორიტეტული თანმიმდევრობით:
            <ul>
              <li>ურთიერთშეხვედრებში ნაჩვენები შედეგი</li>
              <li>გატანილი და გაშვებული ბურთების უკეთესი სხვაობა</li>
              <li>მოგებათა რაოდენობა</li>
              <li>გადათამაშება</li>
            </ul>
          </li>
          <li>პლეიოფის შეხვედრები შედგება 1 თამაშისგან, იმ შემთხვევაში, თუკი თამაშის შედეგი იქნება თანაბარი, გამარჯვებულის ვინაობის დასადგენად, მატჩის დასრულების შემდეგ დაინიშნება ორი 5 წუთიანი ტაიმი. თუ დამატებითი დრო დასრულდა ფრედ, ინიშნება
            პენალტების სერია 5-5 (ხუთ-ხუთი) დარტყმა.

          </li>
        </AccordionComponent>

        <AccordionComponent v-bind:title="'ტურნირში მონაწილე მხარეებისა და ოფიციალური პირების პასუხისმგებლობა'">
          <li>სტადიონზე არსებულ წესრიგზე და უსაფრთოხებაზე გუნდებს ეკისრებათ პასუხისმგებლობა, როგორც მატჩამდე, ისე მატჩის განმავლობაში და მატჩის შემდგომ;</li>
          <li>თუკი მატჩის არბიტრის გადაწყვეტილებით შეხვედრა შეწყდება, ერთ-ერთი გუნდის ფეხბურთელის ან რომელიმე წარმომადგენლის არადისციპლინირებული საქციელის გამო ან თუ გუნდი თვითნებურად დატოვებს მოედანს, ამ გუნდს ჩაეთვლება წაგება 0-5, ხოლო მის
            მოწინააღმდეგეს ჩაეთვლება მოგება 5-0. თუკი მატჩის შეწყვეტის მომენტისთვის, ანგარიშში სხვაობა უფრო დიდი იქნა, ამ შემთხვევაში ანგარიში დარჩება ძალაში. თუკი მატჩის შეწყვეტა ორივე გუნდის მიზეზით მოხდა, ამ შემთხვევაში ორივე მათგანს ჩაეთვლება
            წაგება 0-5;
          </li>
          <li>ორივე გუნდის მხრიდან რეგლამენტის დარღვევის შემთხვევაში, ორივე მათგანს ჩაეთვლება წაგება 0-5;</li>
          <li>მატჩი შეწყდება, თუ ფეხბურთელების გაძევების შემთხვევაში გუნდი დარჩება 3 (სამი) ფეხბურთელით, ამასთან გუნდს ჩაეთვლება წაგება 0-5, მის მოწინააღმდეგეს კი მოგება 5-0. თუკი მატჩის შეწყვეტის მომენტისთვის, ანგარიშში სხვაობა უფრო დიდი იქნა, ამ
            შემთხვევაში ანგარიში დარჩება ძალაში, ხოლო გუნდს ჩაეთვლება წაგება. თუ გაძევებების შედეგად, ორივე გუნდი დარჩება 3-3 (სამ-სამი) ფეხბურთელით, ორივე მათგანს ჩაეთვლება წაგება 0-5;</li>
        </AccordionComponent>
        <AccordionComponent v-bind:title="'ჩემპიონატზე მოქმედებს შემდეგი დისციპლინარული სასჯელები'">
          <li>ყოველ სამ ყვითელ ბარათზე, ავტომატური 1 (ერთი) მატჩიანი დისკვალიფიკაცია.</li>
          <li>ერთ მატჩში ორი ყვითელი ბარათის შემთხვევაში, მოთამაშეს ეკრძალება თამაშის გაგრძელება, გუნდი კი 5 წუთის განმავლობაში ნაკლული მოთამაშით ითამაშებს.</li>
          <li>წითელი ბარათის შემთხვევაში მოთამაშეს ეკრძალება თამაშის გაგრძელება, გუნდი კი 5 წუთის განმავლობაში ნაკლული მოთამაშით ითამაშებს.</li>
          <li>მსაჯის ფიზიკური შეურაცხყოფა ან/და ფიზიკური შეურაცხყოფის მცდელობა (მათ შორის ხელის კვრა, შეფურთხება) - 5 (ხუთი) მატჩიანი დისკვალიფიკაცია.</li>
          <li>ზემოთ ჩამოთვლილი პუნქტებში მოცემული დისციპლინარული დარღვევების გამეორების შემთხვევაში, დამრღვევ პირს სამუდამოდ აეკრძალება იუთუბის ლიგაში მონაწილეობა.</li>
          <li>ჩხუბი (პირი, რომელმაც გამოიწვია ჩხუბი ან მონაწილეობა მიიღო ჩხუბში) - არანაკლებ 5 (ხუთი) მატჩიანი დისკვალიფიკაცია.</li>
          <li>თანაგუნდელების, მოწინააღმდეგის, მსაჯის ან გულშემატკივრის მიმართ უცენზურო და შეურაცხმყოფელი ჟესტების და რეპლიკების გამოყენების გამო, ასევე მსაჯების მიმართ მუქარა-არანაკლებ 4 (ოთხი) მატჩიანი დისკვალიფიკაცია.</li>
          <li>განზრახ ხელის კვრა, ჩადენილი მოთამაშის ან კლუბის ოფიციალური პირის მიერ მატჩამდე, მატჩისას და მატჩის შემდეგ, მეტოქის ან მატჩის ოფიციალური პირების მიმართ - ისჯება მინიმუმ 4 (ოთხი) მატჩიანი დისკვალიფიკაციით.</li>
          <li>მატჩის მსვლელობაში ან მატჩის შემდეგ იმ პირების ჩარევა, რომლებიც არ არიან მოთამაშეები ან კლუბის ოფიციალური პირები (მაყურებელი), რამაც გამოიწვია მასიური არეულობა, რის გამოც ჩაიშალა მატჩი, დამნაშავე კლუბი - ისჯება წაგების ჩათვლით,
            განმეორებით შემთხვევაში მოიხსნება გათამაშებიდან.</li>
          <li>დამატებით სხვა ან/და განსაკუთრებული დარღვევების შემთხვევაში სადისციპლინო სასჯელის შესახებ გადაწყვეტილებას მიიღებს ჩემპიონატის სადისციპლინო კომიტეტი.</li>
          <li>პლეი–ოფის წინ და ფინალის წინ უქმდება ყველა ყვითელი ბარათი. გაუქმებას არ ექვემდებარება დისციპლინარული სანქციების გამო მიღებული დისკვალიფიკაცია.</li>
          <li>გუნდს ჩაეთვლება ტექნიკური წაგება 0-5, იმ შემთხვევაში თუ, თამაშში მონაწილეობას მიიღებს:
            <ul>
              <li>განაცხადში არ მყოფი ფეხბურთელი ან ფეხბურთელი, რომელიც არასწორედ იქნა განაცხადში შეყვანილი;</li>
              <li>ფეხბურთელი, რომელიც სხვისი სახელით და გვარით არის რეგისტრირებული;</li>
              <li>დისკვალიფიცირებული ფეხბურთელი;</li>
            </ul>
          </li>
          <li>თუ ჩემპიონატიდან მოხსნილმა გუნდმა ჩაატარა ჩემპიონატის ყველა თამაშის ნახევარზე ნაკლები, მისი შედეგები გაუქმდება და გუნდი ამოღებული იქნება გათამაშების ცხრილიდან. მატჩების ნახევრის ან ნახევარზე მეტი ჩატარების შემთხვევაში, გუნდს ყველა
            დარჩენილ თამაშში ჩაეთვლება წაგება 0-5 (მოწინააღმდეგეს მოგება 5-0).</li>
        </AccordionComponent>
        <AccordionComponent v-bind:title="'გუნდების დაკომპლექტების კრიტერიუმი'">
          <li class="none">გუნდები, ორგანიზატორის მიერ, შეირჩა შემდეგი კრიტერიუმების გათვალისწინებით:
            <ul>
              <li>მოთამაშე არის ცნობადი სახე, ჰყავს გამომწერები რომელიმე სოციალურ მედიაში (TikTok, Instagram, YouTube) და თავად ქმნის კონტეტს. არ ითვლება მუსიკალური არხები და ა.შ. არ აქვს ნათამაშები პროფესიონალური ფეხბურთი, ფუტსალი ან მინი ფეხბურთი
                უკანასკნელი 2 წლის განმავლობაში</li>
              <li>მოთამაშე ირიცხება რომელიმე YouTube არხზე და პირადად აქვს შეხება კონტენტის წარმოებასთან. არის პროდიუსერი, რეჟისორი ან/და ოპერატორი. არ აქვს ნათამაშები პროფესიონალური ფეხბურთი უკანასკნელი 5 წლის განმავლობაში</li>
              <li>მოთამაშე არ ირიცხება რომელიმე იუთუბ არხზე და არასდროს უთამაშია პროფესიონალური ფეხბურთი ან მინი ფეხბურთი</li>
              <li>თუ მოთამაშე არის არხის მიმართულებით საქმიანობაში ჩართული, მაგრამ არ ჩანს ეკრანზე, აუცილებელია ამ მოთამაშეს ჰქონდეს, მინიმუმ 1 წელი ნამუშევარი ამ არხზე</li>
              <li>იუთუბ ლიგის ბორდი იტოვებს შესაძლებლობას დაუშვას გამონაკლისები</li>
              <li>მოთამაშე არის 18+</li>
            </ul>
          </li>
        </AccordionComponent>
        <AccordionComponent v-bind:title="'ჩემპიონატის განრიგი'">
          <li>ჯგუფური ეტაპის კენჭისყრა ჩატარდება 23 იანვარს.</li>
          <li>ჯგუფური ეტაპები დაიწყება 16 თებერვალს და დასრულდება 28 აპრილს..</li>
          <li>ფლეიოფების კენჭისყრა ჩატარდება 30 აპრილს.</li>
          <li>ფლეიოფები დაიწყება 11 მაისს და დასრულდება 26 მაისს.</li>
          <li>ფინალი ჩატარდება 8 ივნისს. </li>
          <li>ჯგუფური ეტაპის თამაშები შედგება სამ პარასკევს და ყოველ შაბათ-კვირას, დღეში 2 თამაში.</li>
          <li>ფლეიოფის თამაშები შედგება ყოველ შაბათ-კვირას, დღეში 2 თამაში.</li>
        </AccordionComponent>
        <AccordionComponent v-bind:title="'პრიზები'">
          <li>გამარჯვებულ გუნდს გადაეცემა 30 000 ლარი.</li>
          <li>Მეორე ადგილზე გასულ გუნდს გადაეცემა 15 000 ლარი.</li>
          <li>Მესამე ადგილზე გასულ გუნდს გადაეცემა 5 000 ლარი.</li>
        </AccordionComponent>

        <AccordionComponent v-bind:title="'სად ვხვდებით?'">
          <div class="map-section pb-0 md-px-30">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11901.363522291678!2d44.7735595!3d41.7778667!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40446dec619a2237%3A0x53b68c3668768e53!2sDinamo%20Academy!5e0!3m2!1sen!2sge!4v1706525263872!5m2!1sen!2sge&z=13"
                    width="100%" height="100%" class="mapFrame isMap2"></iframe>
          </div>
        </AccordionComponent>
      </div>
    </TermsWrapper>
    <StatsPopUpComponent :StatsData="StatsData" :isXSWidth="isXSWidth" :months="months" :selectedMonth="selectedMonth" @closeStatsPopUp="CloseStatsPopUp" v-if="showStatsPopUp"></StatsPopUpComponent>
    <CustomCard @ToggleCustomCard="ToggleCustomCard" :CustomCardId="CustomCardId" v-if="ShowCustomCard"></CustomCard>
  </div>
</template>
<script>
import TermsWrapper from "@/components/accordion/terms_wrapper.vue";
import AccordionComponent from "@/components/accordion/index.vue";
import Countdown from "@/components/countdown/index.vue";
import VideoComponent from "@/components/video/index.vue";
import StatsComponent from "@/components/stats/index.vue";
import SpecialCardsComponent from "@/components/special-cards/index.vue";
import ScoresComponent from "@/components/scores/index.vue";
import WinnerSurveyComponent from "@/components/winner-survey/index.vue";
import ResultsSurveyComponent from "@/components/results-survey/index.vue";
import ResultsSurveyPosterComponent from "@/components/results-survey-poster/index.vue";
import CustomCard from "@/components/custom-card/index.vue";
import StatsPopUpComponent from "@/components/stats-popup/index.vue";
import VideosCarouselComponent from "@/components/videoscarousel/index.vue";
import moment from "moment";
import * as rasterizeHTML from "rasterizehtml";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import axios from "axios";
export default {
  name: "MainComponent",
  props: {
    isXSWidth: Boolean
  },
  components: {
    CustomCard,
    TermsWrapper,
    AccordionComponent,
    Swiper,
    SwiperSlide,
    Countdown,
    VideoComponent,
    StatsComponent,
    StatsPopUpComponent,
    SpecialCardsComponent,
    ScoresComponent,
    WinnerSurveyComponent,
    ResultsSurveyComponent,
    ResultsSurveyPosterComponent,
    VideosCarouselComponent,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      CustomCardId: null,
      TeamsData: [],
      StatsData: [],
      ScoresData: [],
      GalleryData: null,
      BombardiersData: [],
      AssistsData: [],
      HomeVideoList: [],
      WinnerSurveyData: [],
      ShowCustomCard: false,
      showStatsPopUp: false,
      TimeLeft: {
        Days: 0,
        Hours: 0,
        Minutes: 0,
        Seconds: 0,
      },
      liveUrl: null,
      liveStart: null,
      liveEnd: null,
      selectedMonth: 1,
      months:[
        { id: 1, text: 'თებერვალი' },
        { id: 2, text: 'მარტი'},
        { id: 3, text: 'აპრილი' },
        { id: 4, text: 'მაისი' },
        { id: 5, text: 'ივნისი' },
      
        
      ]
     // AnnouncementTime: moment(
       // "15-05-2023 00:00:00",
       // "DD-MM-YYYY hh:mm:ss"
      // ).isBefore(new Date()),
      // AnnouncementTime: moment().isBetween(moment("17-09-2022 22:00", "DD-MM-YYYY hh:mm"), end),
      
    };
  },
  methods: {
  
    ToggleCustomCard() {
      this.ShowCustomCard = !this.ShowCustomCard;
    },
    OpenStatsPopUp() {
      this.showStatsPopUp = true;
     
    },
    CloseStatsPopUp() {
      this.showStatsPopUp = false;
    },
    getDateLeft() {
      let mimDate = moment("16-02-2024 19:00:00", "DD-MM-YYYY HH:mm:ss");
      let timeDuration = moment.duration(mimDate.diff(moment()));
      let ms = timeDuration.asMilliseconds();
      if (ms <= 0) {
        ms = 0;
        return;
      }
      let totaldays = Math.floor(ms / 86400000);
      let daysms = ms % 86400000;
      let totalhours = Math.floor(daysms / 3600000);
      let minutes = Math.floor((ms % 3600000) / 60000);
      let minutesms = ms % (60 * 1000);
      let seconds = Math.floor(minutesms / 1000);

      (this.TimeLeft.Days =
        totaldays.toString().length === 1
          ? "0" + totaldays.toString()
          : totaldays.toString()),
        (this.TimeLeft.Hours =
          totalhours.toString().length === 1
            ? "0" + totalhours.toString()
            : totalhours.toString()),
        (this.TimeLeft.Minutes =
          minutes.toString().length === 1
            ? "0" + minutes.toString()
            : minutes.toString()),
        (this.TimeLeft.Seconds =
          seconds.toString().length === 1
            ? "0" + seconds.toString()
            : seconds.toString());
    },
    GetMainData() {
      axios
        .get(
          `${process.env.VUE_APP_DATA_API_BASE_URL}/youtubelegends/home-page-data`
        )
        .then((response) => {
          console.log("GETGAMES", response.data.gamesOnDates);
          console.log("GETGAMES111", response.data);
          this.StatsData = response.data.gamesOnDates;
          this.ScoresData = response.data.standingTeams;
          this.BombardiersData = response.data.bombardiers;
          this.AssistsData = response.data.assists;
          this.WinnerSurveyData = response.data.whoWouldWinTeams;
          this.HomeVideoList = response.data.videos;
        })
        .catch((error) => console.log("Error From Server ", error));
    },
    GetLiveData() {
      axios
        .get(
          `${process.env.VUE_APP_DATA_API_BASE_URL}/youtubelegends/home-page-data`
        )
        .then((response) => {
          console.log("GETVIDEODATA", response.data.sectionConfigurations);
          this.liveUrl = response.data.sectionConfigurations.homePageLiveUrl;
          this.liveStart = response.data.sectionConfigurations.streamStartDateTime;
          this.liveEnd = response.data.sectionConfigurations.streamEndDateTime;
          this.BombardiersData = response.data.bombardiers;
          this.AssistsData = response.data.assists;
          this.WinnerSurveyData = response.data.whoWouldWinTeams;
          this.HomeVideoList = response.data.videos;
        })
        .catch((error) => console.log("Error From Server ", error));
    },
    GetGalleryData() {
      axios
        .get(
          `${process.env.VUE_APP_DATA_API_BASE_URL}/youtubelegends/highlights-videos-articles`
        )
        .then((response) => {
          console.log("GetGalleryData", response.data.gamesOnDates);
          this.GalleryData = response.data;
        })
        .catch((error) => console.log("Error From Server ", error));
    },

    ChooseWinnerTeam(id) {
      console.log("ChooseWinnerTeam REQ", id);
      axios
        .post(
          `${process.env.VUE_APP_DATA_API_BASE_URL}/youtubelegends/who-would-win-championship/vote`,
          { teamId: id }
        )
        .then((response) => {
          console.log("ChooseWinnerTeam RESPONSE:", response);
        })
        .catch((error) => console.log("Error From Server ", error));
    },
    ChooseGameWinner(data) {
      console.log("ChooseWinnerTeam REQ", data);
      axios
        .post(
          `${process.env.VUE_APP_DATA_API_BASE_URL}/youtubelegends/how-would-game-end/vote`,
          data
        )
        .then((response) => {
          console.log("ChooseGameWinner RESPONSE: ", response);
        })
        .catch((error) => console.log("Error From Server ", error));
    },
 
  
  },
  computed: {
      IsStart() {
      return moment(this.liveStart);
      //  let end = moment(this.liveEnd, "DD-MM-YYYY hh:mm");
     },
       IsEnd() {
      return moment(this.liveEnd);
     },
     IsLiveTime() {
      // let start = moment(this.liveStart, moment.ISO_8601);
      //  let end = moment(this.liveEnd, "DD-MM-YYYY hh:mm");
    return moment().isBetween(this.IsStart, this.IsEnd)
     },
     videoId(){
      return this.liveUrl.split('?v=')[1];  // yDPdE7S6gtA;
     },
    countdownObj: function () {
      return {
        TimeLeft: this.TimeLeft,
      };
    },
   
  },
  beforeMount() {
    this.getDateLeft();
   
  },
  mounted() {
     this.GetMainData();
    this.GetLiveData();

    var self = this;
    setInterval(function () {
      self.getDateLeft();
    }, 1000);
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/eb-mixin.scss";
.counter-wrapper {
  opacity: 0;
}
.txt-gray {
  color: rgb(106, 106, 106);
  text-decoration: underline !important;
}
.txt-black {
  color: #000;
}
.text-uppercase {
  text-transform: uppercase;
  -moz-font-feature-settings: "case";
  -webkit-font-feature-settings: "case";
  font-feature-settings: "case" on;
}
.main-component {
  width: 100%;
  min-height: 100%;

  .map-section {
    overflow: hidden;
    border-radius: 12px;
    iframe {
      border: 0;
      outline: none;
      min-height: 421px;
      margin-top: -65px;
      border-radius: 12px;
      @media only screen and (max-width: 600px) {
        min-height: 401px;
      }
    }
  }
  .header {
    position: relative;
    display: flex;
    align-items: flex-start;
    z-index: 0;
    .header-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1661px;
      background-image: url("../../assets/bg.webp");
      background-repeat: no-repeat;

      background-position: center top -40px;
      background-size: 1920px;
      z-index: -1;
      @media only screen and (max-width: 1366px) {
        background-size: 1692px;
      }
      @media only screen and (max-width: 1080px) {
        background-position: center top 20px;
        background-size: 1280px;
      }
      @media only screen and (max-width: 768px) {
        background-position: center top 50px;
        background-size: 980px;
      }
      @media only screen and (max-width: 600px) {
        background-position: center top 70px;
        background-size: 720px;
      }
      @media only screen and (max-width: 500px) {
        background-position: center top 80px;
        background-size: 680px;
      }
      @media only screen and (max-width: 400px) {
        background-position: center top 120px;
        background-size: 520px;
      }
    }
    .header-inner {
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
      .header-text {
        text-align: center;
        padding: 0px 25px 0;
        margin-top: 40px;
        .isTitle {
          color: #fff;
          font-size: 37px;
          @include interpolate(font-size, 1366px, 1920px, 37px, 37px);
          line-height: 1.1;
          margin-bottom: 20px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 0 0px 12px;
          min-height: 115px;
          @include interpolate(min-height, 1366px, 1920px, 115px, 115px);
          width: 100%;
          max-width: 417px;
          margin-left: auto;
          margin-right: auto;
          background: url("../../assets/home-component/home-header-title-bg.png")
            no-repeat center;
          background-size: 100% 100%;
          img {
            max-width: 100%;
            height: auto;
            display: block;
          }
        }
        .isDesc {
          color: #000;
          font-size: 54px;

          @include interpolate(font-size, 1366px, 1920px, 44px, 54px);
          margin-bottom: 0px;
          line-height: 1.1;
        }

        @media only screen and (max-width: 1366px) {
          margin-top: 30px;
          .isTitle {
            font-size: 37px;
            @include interpolate(font-size, 768px, 1366px, 24px, 37px);
            min-height: 115px;
            @include interpolate(min-height, 768px, 1366px, 82px, 115px);
            margin-bottom: 10px;
          }
          .isDesc {
            font-size: 44px;
            @include interpolate(font-size, 768px, 1366px, 28px, 44px);
          }
        }
        @media only screen and (max-width: 768px) {
          margin-top: 15px;
          padding: 0;
          .isTitle {
            padding: 0 0 8px;
            text-transform: lowercase;
            font-feature-settings: "case" off;
            font-size: 24px;
            max-width: 240px;
            @include interpolate(font-size, 375px, 768px, 14px, 24px);
            min-height: 82px;
            @include interpolate(min-height, 375px, 768px, 52px, 82px);
            margin-bottom: 4px;
            img {
              height: 24px;
            }
          }
          .isDesc {
            font-size: 28px;
            @include interpolate(font-size, 375, 768px, 18px, 28px);
          }
        }
      }
    }
    &.isLiveTime {
      min-height: unset;
      .header-bg {
        display: none;
      }
    }

    @media only screen and (max-width: 400px) {
    }
  }
  .main-page {
    background-color: transparent;
    color: #000;
    text-align: center;
    // max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    .offer-wrapper {
      margin: 100px 0 80px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      .offer-box {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 28px;
        @include interpolate(font-size, 1366px, 1920px, 20px, 28px);
        img {
          max-width: 130px;
          @include interpolate(max-width, 1366px, 1920px, 93px, 130px);
          margin-right: 40px;
          @include interpolate(margin-right, 1366px, 1920px, 25px, 40px);
        }
        &.offer-box-1 {
          flex: 1;
          max-width: 430px;
        }
        &.offer-box-2 {
          flex: 1;
          max-width: 378px;
        }
        &.offer-box-3 {
          flex: 1.5;
          max-width: 738px;
        }
      }
      @media only screen and (max-width: 1366px) {
        margin: 40px 0 70px;
        .offer-box {
          font-size: 20px;
          @include interpolate(font-size, 768px, 1366px, 16px, 20px);
          img {
            max-width: 93px;
            @include interpolate(max-width, 768px, 1366px, 63px, 93px);
            margin-right: 25px;
            @include interpolate(margin-right, 768px, 1366px, 16px, 25px);
          }
        }
      }
      @media only screen and (max-width: 768px) {
        margin: 35px 0 30px;
        flex-wrap: wrap;
        .offer-box {
          font-size: 16px;
          margin-bottom: 12px;
          justify-content: flex-start;
          @include interpolate(font-size, 375px, 768px, 14px, 16px);
          img {
            max-width: 63px;
            @include interpolate(max-width, 375px, 768px, 43px, 63px);
            margin-right: 16px;
            @include interpolate(margin-right, 375px, 768px, 10px, 16px);
          }
          &.offer-box-1 {
            flex-basis: 51%;
          }
          &.offer-box-2 {
            flex-basis: 49%;
          }
          &.offer-box-3 {
            flex-basis: 100%;
          }
        }
      }
      @media only screen and (max-width: 370px) {
        justify-content: flex-start;
        .offer-box {
          justify-content: flex-start;
        }
      }
    }
    .stats-wrapper {
      margin: 0;
      //  margin-bottom: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: wrap;
      .stats-box {
        margin-bottom: 14px;
      }
      @media only screen and (max-width: 1366px) {
        .special-cards {
          order: -1;
        }
      }
      @media only screen and (max-width: 992px) {
        // .special-cards {
        //   order: -1;
        // }
      }
      @media only screen and (max-width: 768px) {
        margin-bottom: 0;
      }
    }
    .post-carousel-wrapper {
      margin: 0px 0;
      display: flex;
      text-align: left;
      flex-direction: column;
      overflow: hidden;
      padding-top: 40px;
      .title {
        margin-bottom: 4px;
      }
      &.add-margin {
        margin-bottom: 30px;
      }

      @media only screen and (max-width: 768px) {
        overflow: visible;
        &.add-margin {
          margin-bottom: 0;
          padding-top: 14px;
        }
        .title {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
