<template>
  <div class="results-survey stats-box has-poster">

    <div class="poster">
      <a :href="showFantazyPoster ? 'http://fantasy.youtubelegends.ge/': 'https://www.youtube.com/@EuropopBuzz'" target="_blank" class="poster-link">
        <img :src="require(showFantazyPoster ?  '@/assets/results-survey-component/poster-fantazy.png' : '@/assets/results-survey-component/poster-europop.jpg')" alt=""></a>
    </div>

  </div>

</template>

<script>
import moment from "moment";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ResultsSurveyPosterComponent",

  data() {
    return {showFantazyPoster: false};
  },

  mounted() {},
};
</script>

<style lang="scss">
.results-survey {
  width: calc(50% - 17.5px);
  height: 600px;
  margin-left: 17.5px;
  background-color: #fff;
  padding: 24px 16px;
  border-radius: 10px;
  z-index: 9;
  position: relative;

  .poster {
    border-radius: 10px;
    height: 100%;
    .poster-link {
      border-radius: 10px;
      height: 100%;
      img {
        border-radius: 10px;
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &.has-poster {
    padding: 0;
  }

  @media only screen and (max-width: 1366px) {
    width: calc(35% - 10px);
    margin-left: 10px;
    // height: auto;
  }
  @media only screen and (max-width: 1200px) {
    width: calc(100% - 0px);
    margin-left: 0px;
    height: auto;
  }

  @media only screen and (max-width: 768px) {
    height: auto;
    padding: 24px 10px;
    .isTitle,
    .isDate {
      font-size: 14px;
    }
    .groups-item {
      &:last-child {
        padding-bottom: 20px;
      }
    }
    .isContent {
      .or-icon {
        font-size: 12px;
        width: 28px;
        height: 28px;
        right: 0px;
        top: calc(50% - 14px);
        padding-top: 5px;
        transform: none;
      }
      .games-item {
        width: calc(100% - 16px);
        padding: 10px 10px 10px 10px;
        .teams-item {
          padding: 0 10px;
          margin: 0px;
          margin-bottom: 0;
          height: 50px;
          .teams-item-text {
            font-size: 14px;
          }
          &:last-child {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>