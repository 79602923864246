<template>
  <div class="scores stats-box">
    <div class="tabs">
      <div :class="{ activeTab: active == 0 }" @click="active = 0" class="tab">ცხრილი</div>
      <div :class="{ activeTab: active == 1 }" @click="active = 1" class="tab">სტატისტიკა</div>
    </div>
    <div class="scores-body">
      <div v-if="active == 0" class="tableHeader text-font16 eb-font-bold">
        <div class="group">გუნდი</div>
        <div class="scoresContainer">
          <div class="win">მ</div>
          <div class="ball">
            <span>+</span>
            <span>-</span>
          </div>
          <div class="point">ქ</div>
        </div>
      </div>

      <div v-if="active == 0" class="scores-list">
        <div class="table-group" v-for="(group, i) in scoresGroup" :key="i">
          <div class="groupName text-font13 eb-font-medium">{{ group.groupData.length > 0 ? group.groupName : "" }}</div>
          <div class="tableBody" v-for="(team, j) in group.groupData" :key="j">
            <div class="tableItems">
              <div class="team-container text-font14 eb-font-medium">
                <div class="number-container">
                  <span>{{ j + 1 }}</span><span class="dot"></span>
                </div>
                <div class="image">
                  <img :src="team.imageUrl" />
                </div>
                <div class="fullName">
                  <span class="eb-font-bold">{{ team.name }}</span>
                </div>
              </div>
              <div class="results">
                <div>{{ team.gamesCount }}</div>
                <div>{{ team.scoresDifference }}</div>
                <div>{{ team.score }}</div>
              </div>
            </div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div v-if="active == 1" class="scores-list">
        <div class="bombardirs">
          <div class="line"></div>
          <div class="bombardirName" v-if="BombardiersData.length > 0">ბომბარდირები</div>
          <div class="line"></div>
          <div v-if="active == 1 && BombardiersData.length > 0" class="tableHeader text-font16 eb-font-bold">
            <div class="headerItem">სახელი</div>
            <div class="headerItem">გოლი</div>
          </div>
          <div class="tableBody" v-for="(item, i) in BombardiersData" :key="i">
            <div class="team-container text-font14 eb-font-medium">
              <div class="leftSide">
                <div class="number-container">
                  <span>{{ i + 1 }}</span>
                  <span class="dot"></span>
                </div>
                <div class="image">
                  <img :src="item.imageUrl" />
                </div>
                <div class="fullname">
                  <span class="eb-font-bold name">{{ item.fullName }}</span>
                </div>
              </div>
              <div class="results">
                <div class="goal">{{ item.goalsCount }}</div>
              </div>
            </div>
            <div class="line"></div>
          </div>
        </div>

        <div class="bombardirs">
          <div class="line"></div>
          <div class="bombardirName" v-if="AssistsData.length > 0">ასისტი</div>
          <div class="line"></div>
          <div v-if="active == 1 && AssistsData.length > 0" class="tableHeader text-font16 eb-font-bold">
            <div class="headerItem">სახელი</div>
            <div class="headerItem">საგოლე გადაცემა</div>
          </div>
          <div class="tableBody" v-for="(elem, j) in AssistsData" :key="j">
            <div class="team-container text-font14 eb-font-medium">
              <div class="leftSide">
                <div class="number-container">
                  <span>{{ j + 1 }}</span>
                  <span class="dot"></span>
                </div>
                <div class="image">
                  <img :src="elem.imageUrl" />
                </div>
                <div class="fullname">
                  <span class="eb-font-bold name">{{ elem.fullName }}</span>
                </div>
              </div>
              <div class="results">
                <div class="goal">{{ elem.assistsCount }}</div>
              </div>
            </div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    ScoresData: Array,
    BombardiersData: Array,
    AssistsData:Array
  },
  mounted() {},
  data() {
    return {
      active: 0,
    };
  },
  computed: {
    scoresGroup() {
      let allData = [
        {
          groupName: "Group A",
          groupData: [],
        },
        {
          groupName: "Group B",
          groupData: [],
        },
      ];
      this.ScoresData.map((item) => {
        if (item.standingGroup === 1) {
          item ? allData[0].groupData.push(item) : null;
        } else {
          item ? allData[1].groupData.push(item) : null;
        }
      });
      return allData;
    },
  },
};
</script>

<style lang="scss">
.scores {
  width: 22%;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  min-height: 368px;
  max-height: 665px;
  // max-width: 368px;
  // min-width: 368px;
  // flex-basis: 368px;
  overflow: hidden;
  .image {
    flex: unset !important;
    width: 25px;
    margin: 0 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: auto;
    }
  }

  .scores-body {
    width: 100%;
    max-height: 590px;
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      background-color: #f15b25;
      border-radius: 10px;
      visibility: hidden;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      background: none;
    }

    &::-webkit-scrollbar {
      width: 2px !important;
    }
  }
  .scores-list {
    width: 100%;
  }
  .bombardirs {
    .bombardirName {
      padding: 20px 0px;
    }
    .team-container {
      padding: 20px 24px;
      width: 100%;
    }
  }
  .line {
    width: 100%;
    background-color: #f1f2f3;
    height: 1px;
    //margin-top: 8px;
    // margin-bottom: 8px;
  }
  .groupName {
    margin: 16px 0;
  }
  .tableBody {
    width: 100%;

    // padding: 24px;
    .tableItems {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
    }
  }
  .team-container {
    display: flex;
    align-items: center;
    justify-content: center;
    //flex: 1;
    width: 50%;
    justify-content: flex-start;
    padding-left: 24px;

    .leftSide {
      display: flex;
      align-items: center;
      // flex: 1;
      width: 50%;
      .name {
        white-space: nowrap;
      }
      .fullname {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .number-container {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 30px;
      .dot {
        height: 4px;
        width: 4px;
        background-color: #f05b24;
        border-radius: 50%;
        display: flex;
        margin-left: 16px;
      }
    }
  }
  .results {
    display: flex;
    justify-content: space-between;
    //  flex: 1;
    width: 50%;
    text-align: center;
    div {
      flex: 1;
      text-align: center;
      color: rgba(0, 0, 0, 0.4);
      &:last-child {
        color: rgba(0, 0, 0, 1);
      }
    }
  }
  .tableHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    text-align: center;
    background-color: #fbfbfb;
    .headerItem {
      padding: 20px 24px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #f15b25;

      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 5px !important;
    }
    .scoresContainer {
      display: flex;
      padding: 16px 0;
      .ball {
        display: flex;
        flex-direction: column;
        :nth-child(2) {
          margin-top: -14px;
        }
      }
    }
    div {
      //  flex: 1;
      width: 50%;
      text-align: center;
    }
  }
  .tabs {
    display: flex;
    width: 100%;
    .tab {
      flex: 1;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #767676;
      &.activeTab {
        background-color: #f05b24;
        color: #fff;
      }
    }
  }
  @media only screen and (max-width: 1366px) {
    width: 35%;
    max-height: 600px;
  }

  @media only screen and (max-width: 992px) {
    width: 100%;
    max-height: unset;
    .scores-body {
      max-height: unset;
    }
  }
}
</style>
