<template>
  <div id="app">
    <div class="main-wrapper" @scroll="pageScrolled($event)" :class="{'dark-bg': showMobMenu}">
      <div class="fantasy-icon" v-show="showFantazyIcon"><a href="https://fantasy.youtubelegends.ge/" target="_blank"><img :src="require('@/assets/fantasy-icon.png')" alt="" /></a></div>
      <NavbarComponent v-bind="navbarObj"></NavbarComponent>
      <div class="content">
        <router-view :isXSWidth="isXSWidth" />
      </div>
      <FooterComponent></FooterComponent>
    </div>
  </div>
</template>

<script>
import router from "@/router"; 
import ContentService from "@/core/services/content.service";
import CoreService from "@/core/services/core.service";
import PostMessageService from "@/core/services/post-message.service";
import NavbarComponent from "@/components/navbar/index.vue";
import FooterComponent from "@/components/footer/index.vue";
import moment from "moment";
import { mapActions } from "vuex";
import $ from "jquery";
export default {
  components: {
    ContentService,
    PostMessageService,
    NavbarComponent,
    FooterComponent,
  },

  created() {
    this.handleEnvLog();
  },
  data() {
    return {
      isDescWidth: false,
      isTabWidth: false,
      isMobWidth: false,
      isXSWidth: false,
      showMobMenu: false,
      showFantazyIcon: true,
    };
  },
  methods: {
    ...mapActions({
      GetTeams: "GetTeamsData",
      GetGallery: "GetGalleryData",
    }),
    handleEnvLog() {
      if (process.env.VUE_APP_ENV_LOG === "true") {
        console.log("============= Env =============");
        console.log(process.env);
        const sessionToken = this.$route.query?.sessionToken;
        if (sessionToken) {
          localStorage.setItem("sessionToken", sessionToken);
        } else {
          localStorage.removeItem("sessionToken");
        }
      }
    },
     goToFantasyLink(){
      console.log('fff')
      window.open('https://fantasy.youtubelegends.ge/', '_blank');
    },
  
    inViewport($el) {
      var elH = $el.outerHeight(),
        H = $(window).height(),
        r = $el[0].getBoundingClientRect(),
        t = r.top,
        b = r.bottom;
      return Math.max(0, t > 0 ? Math.min(elH, H - t) : Math.min(b, H));
    },
    pageScrolled(event) {
      // this.$root.$emit("bv::hide::popover");
    },
    toggleMenu() {
      this.showMobMenu = this.showMobMenu ? false : true;
    },

    checkWindowWidth() {
      var width = document.body.clientWidth;
      if (width >= 1200) {
        this.isDescWidth = true;
        this.showMobMenu = false;
      } else {
        this.isDescWidth = false;
      }
      if (width >= 768 && width <= 1200) {
        this.isTabWidth = true;
      } else {
        this.isTabWidth = false;
      }
      if (width >= 600 && width <= 768 ) {
        this.isMobWidth = true;
      } else {
        this.isMobWidth = false;
      }
      if (width < 600) {
        this.isXSWidth = true;
      } else {
        this.isXSWidth = false;
      }
    },
    backToTop() {
      var VueScrollTo = require("vue-scrollto");
      var options = {
        container: ".main-wrapper",
        easing: "ease-in",
        lazy: false,
        offset: 0,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      };
      var cancelScroll1 = VueScrollTo.scrollTo("#app", 100, options);
    },
    backToTopNav() {
      this.showMobMenu = false;
      // this.backToTop();
    },
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        to.hash.length > 0 ? null : this.backToTop();
      }
      this.showMobMenu = false;
    },
  },
  computed: {
 
    isMobile() {
      return CoreService.isMobile();
    },
    navbarObj() {
      return {
        showMobMenu: this.showMobMenu,
        toggleMenu: this.toggleMenu,
        backToTopNav: this.backToTopNav,
      };
    },
  },
  created() {
    this.handleEnvLog();
    this.GetTeams();
    this.GetGallery();
  },
  beforeMount() {
    window.addEventListener("resize", this.checkWindowWidth);
    this.checkWindowWidth();
  },
  mounted() {
    console.log("Loading Done");
    PostMessageService.LoadingFinished();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/eb-mixin.scss";
// reset styles
body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img,
abbr {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}
ul li {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
sup {
  vertical-align: text-top;
}
sub {
  vertical-align: text-bottom;
}
input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
legend {
  color: #000;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}
img {
  max-width: 100%;
  height: auto;
}

// main styles

html {
  background-color: #fff;
  height: 100%;
}
body {
  width: 100%;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  font-family: "Helvetica-Roman";
  font-size: 16px;
  line-height: 1.2;
  background-color: #e1e1e1;
  color: #000000;
  margin: 0 auto !important;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url("assets/body-bg.jpg");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    pointer-events: none;
    z-index: -1;
  }
}
#app {
  height: 100%;
}

.wrapper {
  width: 100%;
  max-width: 1920px;
  min-width: 320px;
  margin: 0 auto;
  position: relative;
  padding-left: 100px;
  padding-right: 100px;

  @media only screen and (max-width: 1500px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media only screen and (max-width: 1280px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media only screen and (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.content {
  min-height: calc(100vh - 306px);
  @media only screen and (max-width: 768px) {
    min-height: calc(100vh - 180px);
  }
}
.main-wrapper {
  width: 100%;
  max-width: 1920px;
  overflow: hidden;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  position: relative;
  height: calc(100vh - 0px);
  top: 0;
  overflow-y: scroll;
  scroll-behavior: smooth;

  @-moz-document url-prefix() {
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &::scrollbar-track {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
  }
  &::-webkit-scrollbar {
    width: 5px !important;
  }
  .fantasy-icon {
    position: fixed;
    width: 142px;
    height: 125px;
    right: 0px;
    top: 135px;
    z-index: 999;
    cursor: pointer;
    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
    @media only screen and (min-width: 1920px) {
      right: calc(50% - 960px);
    }
    @media only screen and (max-width: 768px) {
      width: 108px;
      height: 83px;
      top: 127px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    transform: scale(0);
    transform-origin: top center;
    opacity: 0;
  }
  &.dark-bg {
    &:after {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.light-blue {
  color: rgba(0, 162, 175, 1);
}
.mb-20 {
  margin-bottom: 20px;
}
.text-italic {
  font-style: italic;
}
.cursor-pointer {
  cursor: pointer;
}
.no-underline {
  text-decoration: none;
}
</style>
