<template>
  <div class="counter-wrapper">
    <div class="counter-title eb-font-bold text-font14 text-uppercase">ჩემპიონატის დაწყებამდე დარჩა</div>
    <div class="counter">
      <div class="counter-item days">
        <p class="font-hl-bold text-font24 isNum">{{ TimeLeft.Days  }}</p>
        <p class="font-hl-roman text-font10 isText">დღე</p>
        <!-- <div class="line"></div> -->
      </div>
      <!-- <div class=" divide"><span class="font-hl-bold text-font26">:</span></div> -->

      <div class="counter-item hours">
        <p class="font-hl-bold text-font24 isNum">{{ TimeLeft.Hours  }}</p>
        <p class="font-hl-roman text-font10 isText">საათი</p>
        <!-- <div class="line"></div> -->
      </div>
      <!-- <div class=" divide"><span class="font-hl-bold text-font26">:</span></div> -->
      <div class="counter-item minutes">
        <p class="font-hl-bold text-font24 isNum">{{ TimeLeft.Minutes   }}</p>
        <p class="font-hl-roman text-font10 isText">წუთი</p>
        <!-- <div class="line"></div> -->
      </div>
      <!-- <div class="divide"><span class="font-hl-bold text-font26">:</span></div> -->
      <div class="counter-item seconds">
        <p class="font-hl-bold text-font24 isNum">{{ TimeLeft.Seconds   }}</p>
        <p class="font-hl-roman text-font10 isText">წამი</p>
        <!-- <div class="line"></div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Countdown",
  props: {
    TimeLeft: Object,
  },
  data() {
    return {
      resultsOpen: false,
    };
  },
  methods: {},
  computed: {},
};
</script>
<style lang="scss">
.counter-wrapper {
  margin-top: 550px;
  background-color: #2e2e2e;
  border-top: 4px solid #f05a22;
  border-bottom: 8px solid #f05a22;
  border-radius: 10px;
  width: 100%;
  max-width: 326px;
  margin-left: auto;
  margin-right: auto;
  padding: 18px 17px 13px 17px;
  .counter-title {
    text-align: center;
    margin-bottom: 16px;
    color: #fff;
  }
  .counter {
    display: flex;
    justify-content: center;
    align-items: stretch;
    .counter-item {
      background-color: #fff;
      border-radius: 6px;
      margin-left: 4px;
      margin-right: 4px;
      color: #000;
      text-align: center;
      position: relative;
      flex: 1;
      .isNum {
        display: block;
        background-color: #fff;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        padding: 6px 16px 0px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .isText {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 0px 12px 6px 12px;
        min-width: 56px;
        font-size: 10px;
      }
      // .line {
      //   position: absolute;
      //   background-color: #f05a22;
      //   box-shadow: 0px 0px 5px 0px #f05a22;
      //   width: 18px;
      //   height: 3px;
      //   border-top-left-radius: 4px;
      //   border-top-right-radius: 4px;
      //   bottom: 0;
      //   left: calc(50% - 9px);
      // }
    }
    // .divide {
    //   display: flex;
    //   justify-content: center;
    //   align-items: flex-start;
    //   padding-top: 5px;
    // }
    @media only screen and (max-width: 768px) {
      .counter-item {
        .isText {
          // padding: 2px 12px 6px 12px;
          // font-size: 10px;
        }
        .isNum {
          // padding: 6px 14px;
          // font-size: 24px;
        }
      }
    }
  }
  @media only screen and (max-width:768px){
    margin-top: 420px;
  }
  @media only screen and (max-width:768px){
    margin-top: 380px;
  }
}
</style>